import React, { useEffect } from 'react';
import { removeInstitutionTags } from '../../helpers/removeInstitutionTags';
import { getDateInWords } from '../../helpers/getDateInWords';
import { getDocumentType } from '../../helpers/getDocumentType';
import { Metatags } from '../opengraph_seo/Metatags';
import TagsList from '../ui_elements/TagsList';
import Highlighter from "react-highlight-words";
import { findChunks } from '../../helpers/findChunks';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import EditButton from '../ui_elements/EditButton';

export const DocumentHeader = ({ name, combinedName, publicationDate, publicationNumber, description, tags, url, id, issuer, document_type, issueId, alternativeIssueId}) => {

    const { queryValue } = useSelector( state => state.query );
    const { userPermission } = useSelector( state => state.user );

    let actTypeTag = ""
    
    tags.forEach( element => {
        if( element.type == "Tipo de Acto")
            actTypeTag = element.name;
    });

    return (
        <>
            <Metatags
                name={name}
                combinedName={combinedName}
                publicationDate={publicationDate}
                description={description}
                actTypetag={actTypeTag}
                url={url}
                documentId={id}
                issuer={issuer}
                documentType={document_type}
                issueId={issueId}
            >
            </Metatags>
            { document_type == "Auto Acordado" ? 
                (
                    <>
                        <div className="card-title"> 
                            <h1 className="is-title-document">{ `Auto Acordado ${issueId}` }</h1>
                            { userPermission != "" && <EditButton link={`${process.env.REACT_APP_TL_BASE_URL}/documents/${id}/edit`}/> }
                            
                        </div>
                        { alternativeIssueId && (
                            <h1 className="is-title-document">{ alternativeIssueId }</h1>
                        )}
                    </>
                )
                :
                (
                    <div className="card-title">
                        <h1 className="is-title-document">{ combinedName }</h1>
                        { userPermission != "" && <EditButton link={`${process.env.REACT_APP_TL_BASE_URL}/documents/${id}/edit`}/> }
                    </div>
                )
            }
            {/* <p className="has-text-weight-normal is-size-6-mobile">{ description }</p> */}
                <div className="has-text-grey-dark mt-4 mb-4">
                    {
                        !!issuer &&
                        (
                            <section>
                                <img className="document-card-icon" src="/assets/icons/Institution.svg" />
                                <p className=" is-inline-block mr-5 institution-tags">{ issuer }</p>
                            </section>
                        )
                    }
                    {
                        document_type &&
                        <section>
                            <i className="far fa-folder document-card-icon"></i>
                            <p className="is-inline-block mr-5 institution-tags"> { getDocumentType(document_type, true) }  </p>
                        </section>
                    }
                    
                    <section>
                        <img className="document-card-icon" src="/assets/icons/Calendar.svg" />
                        <p className=" is-inline-block mr-5 institution-tags">{ getDateInWords(publicationDate) }</p>
                        {
                            publicationNumber && (
                                <p className="institution-tags mb-0">
                                    <img className="document-card-icon" src="/assets/icons/Gazette.svg" />
                                    Gaceta No. { (publicationNumber != "" && publicationNumber != null) && (`${publicationNumber}` ) }
                                </p>
                            ) 
                        }
                    </section>
                    &nbsp;
                    <TagsList tagsArray = { removeInstitutionTags(tags) }/>
                    
                </div>
                <Highlighter
                    highlightClassName="is-content-text"
                    unhighlightClassName="is-content-text"
                    searchWords={[queryValue]}
                    autoEscape={true}
                    textToHighlight={ description }
                    findChunks={findChunks}
                /> 
        </>
    )
}
