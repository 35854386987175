export const selectImageToDisplay = ( name ) => {

    let imageName = '';
    switch (name) {
        case 'Avisos Legales':
            imageName = 'avisos_legales';
            break;
        case 'Marcas de Fábrica':
            imageName = 'marcas';
            break;
        case 'Auto Acordado':
            imageName = 'auto_acordado';
            break;
        default:
            imageName = 'decretos';
    }

    return imageName;
}