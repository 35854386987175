import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Mixpanel } from '../../helpers/mixpanel'
import { PageVisitTracker } from '../../helpers/PageVisitTracker'
import { useDidUpdate } from '../../hooks/useDidUpdate'
import { AdvancedSearchBar } from '../advanced_search/AdvancedSearchBar'
import { DocScreen } from '../document_screen/DocScreen'
import { Footer } from '../ui_elements/Footer'
import { Search } from '../ui_elements/Search';
import { Wall } from '../wall/Wall'
import { ConfirmEmailWall } from '../wall/ConfirmEmailWall'
import { DeletedDocumentBanner } from '../ui_elements/DeletedDocumentBanner'

export const DocumentPage = () => {

    const { accountInfo } = useSelector((state) => state.user);
    const { id } = accountInfo;
  
    let distinctId = id || Mixpanel.get_distinct_id();
    const { isUserConfirmed, userType } = useSelector((state) => state.user);
    const { documentDetails } = useSelector((state) => state.document);
    
    useEffect(() => {
        const element = document.getElementById('validNavbar');
        element.scrollIntoView({behavior: "smooth"});
        PageVisitTracker(distinctId);
    }, [])

    const handleAdvancedSearch = ( e ) =>{
        // e.preventDefault();
        const advancedSearchModal = document.getElementById("advancedSearchModal");
        advancedSearchModal.classList.toggle('is-active');

    }
    
    return (
        <>
        { (userType != "not logged" && !isUserConfirmed) &&  <ConfirmEmailWall activeWall={false}/>}
        <Wall activeWall = {false} />
        <div className="document-page mt-5 ml-3 mr-3">
            <div className="columns">

                <section className="left-container column is-9 is-desktop">

                    <div className="title-container mt-2 mb-4">
                        <a href="/">
                            <h1 className="is-title-brand has-text-centered" id="headerTitle">Valid de TodoLegal</h1>
                        </a>
                    </div>
                    <div className="columns mt-3">
                        <div className="column is-8 is-offset-2">
                            <Search handleAdvancedSearch = { handleAdvancedSearch } isInDocumentPage = { true }/>
                        </div>
                    </div>
                    <Link className="go-back-button" to="/">
                        <i className="fa-solid fa-arrow-left mr-3"></i>  Regresar a la búsqueda
                    </Link>
                    <hr className=" has-background-grey-lighter"/>
                    {
                        documentDetails ?
                            <DocScreen />
                        :
                            <DeletedDocumentBanner />
                    }

                </section>

                <div className="modal" id="advancedSearchModal">
                    <div className="modal-background"></div>
                    <div className="modal-card advanced-search-container">
                        <section className="modal-card-body">
                            <AdvancedSearchBar handleAdvancedSearch = { handleAdvancedSearch } isMobile = { true } isInDocumentPage = { true } />
                        </section>
                    </div>
                </div>

                <aside className="right-container advanced-search column mt-3 is-3 is-hidden-mobile">
                    <AdvancedSearchBar handleAdvancedSearch = { handleAdvancedSearch } isMobile = { false } isInDocumentPage = { true } />
                </aside>

            </div>
            <Footer />
        </div>
    </>
    )
}