import { createStore, applyMiddleware, compose }from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '../reducers/rootReducer';   

//Con la linea de abajo habilito para poder usar las extensiones del dev-tools junto al midleware que quiero, en este caso 'thunk'
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;


//con el composeEnhancers añado mi middleware
export const store = createStore( 
    rootReducer,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);