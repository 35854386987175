import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';

export const Wall = ({activeWall = true}) => {

  const { canAccess, userType, isUserConfirmed } = useSelector((state) => state.user);
  const [showLoader, setShowLoader] = useState(false);

  let location = useLocation();
  let redirect_url = process.env.REACT_APP_VALID_BASE_URL;
  redirect_url = `${redirect_url}${location.pathname}`;

  let redirect_state = location.pathname;

  const oAuthSignUp = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&go_to_signup=true&state=${redirect_state}`;
  const oAuthLogin = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&state=${redirect_state}`;
  const goToPricing = `${process.env.REACT_APP_TL_BASE_URL}/pricing?return_to=${redirect_url}`
  const whatsAppLink = "https://wa.me/50494714842";

  //Show wall only when user can't access the documents (not pro user or free has trial ended)
  const showWall = !canAccess && userType != "pro" && isUserConfirmed;
  //Modal is active by default when the user is basic and free trial has ended
  const isActive = userType == "basic" && activeWall ? "is-active" : ""

  const basicUser = userType == "basic" ? true : false;

  useLayoutEffect(() => {
    const handleBackButton = () => {
      setShowLoader(false);
    };

    window.addEventListener('popstate', handleBackButton);
    window.addEventListener('pageshow', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('pageshow', handleBackButton);
    };
  }, []);
  
  useEffect (() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';
  
    // Re-enable scrolling when component unmounts
    return () => { document.body.style.overflow = 'unset'; };
  }, []); // Empty array ensures effect is only run on mount and unmount

  const handleBack = (e) => {
    const menu = document.getElementsByClassName('wall');
    e.preventDefault();
    for (const element of menu) {
      element.classList.toggle('is-active');
    }
    
  };

  const handleBackUnconfirmed = (e) => {
    const menu = document.getElementsByClassName('wall-unconfirmed');
    e.preventDefault();

    for (const element of menu) {
      element.classList.toggle('is-active');
    }
  };

  const handleTagManagerEvent = (e, isConfirmed=true) => {
    let popupTrigger = userType === "basic" ? 'Prueba gratis vencida' : 'Sin cuenta';
    popupTrigger = isConfirmed ? popupTrigger : 'Cuenta sin confirmar';
    
    window.dataLayer.push({
      event: 'popup_cta',
      eventModel: {
        popup_trigger: popupTrigger,
        click_text: e.target.text,
        click_url: e.target.href
      },
    });
  }

  const handleLoaderState = ( e ) => {
    handleTagManagerEvent(e, true);
    setShowLoader(true);
  }

  return (
    <>
      {(showWall) && (
        <>
         
          <div className={`modal wall animate__animated animate__fadeIn ${isActive}`}>
            <div className="modal-background" onClick={handleBack}></div>
            <div className="modal-content">
              <div className="box is-dark">
                <div className="delete-container">
                  <button className="delete delete-btn" aria-label="close" onClick={handleBack}></button>
                </div>
                <article className="wall-modal">
                  { basicUser ? 
                      (
                        
                        <>
                          <h3 className="is-title-modal"><strong>Adquiere tu Sucripción</strong></h3>
                          <p className="is-content-text-modal">
                            Tu cuenta ha <b>vencido</b>, para seguir usando nuestras herramientas debes tener una <b>Suscripción Activa</b>.
                          </p>
                        </>
                      ) 
                    : 
                      (
                        <>
                          <h3 className="is-title-modal"><strong>Crea tu cuenta</strong></h3>
                          <p className="is-content-text-modal">
                            Para visualizar este contenido debes tener una <b>Suscripción Activa</b>. Crea una cuenta o inicia sesión con tu usuario.
                          </p>
                        </>
                      )
                  }
                </article>
                <div className="columns mt-4">
                  <div className="column">
                    <div className="buttons modal-buttons is-justify-content-center">
                      <div className="field is-grouped">
                        
                          {
                            !showLoader &&
                            (
                              <>
                                <p className="control">
                                  <a 
                                    href={basicUser ? goToPricing : oAuthSignUp} 
                                    className="button mr-2 is-primary" 
                                    onClick={handleLoaderState} 
                                  >
                                    {basicUser ? "Ver planes" : "Crear cuenta"}
                                  </a>
                                </p>
                                <p className="control">
                                  <a 
                                    href={basicUser ? whatsAppLink : oAuthLogin} 
                                    target={basicUser ? "_blank" : ""} 
                                    className="button is-primary is-light" 
                                    onClick={basicUser ? null : handleLoaderState} 
                                  >
                                    {basicUser ? "Contáctanos" : "¿Tienes usuario?"}
                                  </a>
                                </p>
                              </>
                              )
                          }
                        
                      </div>
                    </div>
                  </div>
                </div>
                { showLoader && <progress className="progress is-small is-info" max="100">15%</progress>}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
