import React, { useEffect, useState } from 'react'

export const PreferencesTagsList = ({ topics, userPreferenceTopics, disabled }) => {

    const [preferenceState, setPreferenceState] = useState({
        topicsArray: []
    })

    // const [ isTagSelected, ]

    const getPreferenceTagsArray = () => {
        let tempArray = [];
        let element = {};

        topics?.forEach(tag => {
            let selectedTag = false;

            userPreferenceTopics?.forEach( preferenceId => {
                if( preferenceId === tag.tag_id ){
                    element = { 
                        id: tag.tag_id,
                        name: tag.tag_name,
                        class: "is-preference-tag tag-selected"
                    }
                    tempArray.push(element);
                    selectedTag = true;
                }
            })

            if (!selectedTag){
                element = { 
                    id: tag.tag_id,
                    name: tag.tag_name,
                    class: "is-preference-tag"
                }
                tempArray.push(element);
                selectedTag = false;
            }
        });

        return tempArray;
    }

    const handleTopicSelection = ( e, elementId ) => {
        document.getElementById(`${elementId}`).classList.toggle('tag-selected');
    }

    //Para guardar y enviar el arreglo de tags, cuando le de submit obtengo todos los tags elementes que tienen la clase tag-selected y luego obtengos sus ids

    useEffect(() => {
        setPreferenceState({
            topicsArray: getPreferenceTagsArray()
        })
        
    }, [topics, userPreferenceTopics])

    const { topicsArray } = preferenceState;

    return (
        <div className="preference-tags">
            {
                topicsArray?.map( (tag, index) => {
                    return(
                        <span
                        id={ tag.id }  
                        key = { tag.id } 
                        className = {`${tag.class} is-inline-block ${disabled}`}
                        title = { tag.id }
                        onClick={ (e) => handleTopicSelection(e, tag.id) }
                        >
                            { tag.name }
                        </span>
                    );
                    
                })
            }
        </div>
    )
    
}
