import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDidUpdate } from '../../hooks/useDidUpdate'
import { applyFilters, resetDateFilter, resetAllTags } from '../../state/actions/query'
import { getTagsList } from '../../state/actions/ui'
import { Dropdown } from '../ui_elements/Dropdown'
import { FilterByDate } from './FilterByDate'


export const AdvancedSearchBar = ({ handleAdvancedSearch, isMobile, isInDocumentPage=false }) => {

    const dispatch = useDispatch();

    const { token } = useSelector( state => state.auth );
    const { fromValue, toValue, queryValue, institutionValue, actTypeValue, topicValue, tags } = useSelector( state => state.query );
    
    const { institutionTags, actTypeTags, topicTags, tagsPending } = useSelector( state => state.ui );

    const [tagsState, setTagsState] = useState({
        loading: true,
        institutions: [],
        actTypes: [],
        topics: [],
    });

    const [startDate, setStartDate] = useState( null );
    const [endDate, setEndtDate] = useState( null );
    

    const handleApplyBtn = ( e ) => {
        const element = document.getElementById('validNavbar');
        element.scrollIntoView({behavior: "smooth"});
        
        let newTags = [institutionValue, actTypeValue, topicValue];
        newTags = newTags.filter(tag => (tag != "" && tag != "Seleccionar opción") && tag != null);
        let filteredTags = tags.concat(newTags)
        filteredTags = [...new Set(filteredTags)];
        dispatch( applyFilters( queryValue, startDate, endDate, filteredTags, token) );

        if( isMobile )
            handleAdvancedSearch( e );
    }

    const handleDeleteFilters = () => {
        
        dispatch( resetAllTags() );
        dispatch( resetDateFilter() );
    }

    useEffect(() => {
        dispatch( getTagsList() );
    }, []);

    useDidUpdate(() => {
        setTagsState({
            institutions: institutionTags,
            actTypes: actTypeTags,
            topics: topicTags,
            loading: tagsPending
        })

    }, [institutionTags, actTypeTags, topicTags]);

    useDidUpdate( () => {
        setStartDate(fromValue);
    }, [fromValue])

    useDidUpdate( () => {
        setEndtDate(toValue);
    }, [toValue])

    const{ institutions, actTypes, topics, loading } = tagsState;

    return (
                
        <>
            <h3 className="title-advanced-search">Búsqueda Avanzada</h3>
            <hr className="mt-0 has-background-grey-light"/>
            <div className="mt-5">
                <FilterByDate from = { startDate } to = { endDate } isMobile={isMobile}/>
            </div>
            
            <div className="mt-5">
                <img className="advanced-search-icon is-inline-block" src="/assets/icons/Institution.svg" />
                <h2 className="is-filter-title is-inline-block"> Institución </h2>
                <Dropdown  values = { institutions } tagType = "institution" defaultValue={institutionValue}/>
            </div>
            <div className="mt-5">
                <img className="advanced-search-icon is-inline-block" src="/assets/icons/Act-type.svg" />
                <h2 className="is-filter-title is-inline-block"> Tipo de Acto </h2>
                <Dropdown values = { actTypes } tagType = "actType" defaultValue={actTypeValue}/>
            </div>
            <div className="mt-5">
                <img className="advanced-search-icon is-inline-block" src="/assets/icons/Subject.svg" />
                <h2 className="is-filter-title is-inline-block"> Tema </h2>
                <Dropdown values = { topics } tagType = "topic" defaultValue={topicValue}/>
            </div>
            <div className="buttons is-justify-content-center mt-3">
                {
                    isInDocumentPage ?
                    ( 
                        <Link className="mr-2" to="/">
                            <button onClick={ handleApplyBtn } className="button is-primary is-normal-button">Aplicar</button>
                        </Link>
                    )
                    :
                    ( <button onClick={ handleApplyBtn } className="button is-primary is-normal-button">Aplicar</button> )
                }
                
                <button onClick={ handleDeleteFilters }className="button is-primary is-normal-button is-outlined button-advanced-search">Borrar filtros</button>
                {/* se puede hacer un buttonElement en la ui */}
            </div>
            <div className="close-advanced-search has-text-centered arrow-advanced-search ">
                <button className="button is-white" onClick={ handleAdvancedSearch }>
                    <img className="close-advanced-search-icon" src="/assets/icons/Arrow.svg" />
                </button>
            </div>
        </>
    )
}