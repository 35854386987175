import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { MailingCard } from './MailingCard';

export const MailchimpFormContainer = () => {

    const postUrl = `https://app.us4.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_FORM_USER}&id=${process.env.REACT_APP_MAILCHIMP_FORM_ID}`;

    return (
        <div className="mailchimp-form-container mb-4">
            <MailchimpSubscribe 
                url={postUrl}
                render={({subscribe, status, message}) => (
                    <MailingCard 
                        status={status}
                        message={message}
                        onValidated={ formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    )
}
