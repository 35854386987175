import { getCardsDate } from "./getCardsDate";


export const setDate = ( documentCards ) => {
    let dateContainer = document.getElementById('dateContainer');
    // const windowHeightWithScrollBar = document.documentElement.clientHeight;
    // const result = windowHeightWithScrollBar - dateContainer.offsetTop 

    const cards = document.querySelectorAll(".document-card")
    const firstCardDate = documentCards[0]?.publication_date;
    
    // window.onload = function() {getCardsDate(cards, dateContainer, firstCardDate)};
    window.onscroll = function() {getCardsDate(cards, dateContainer, firstCardDate)};
}