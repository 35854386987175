import React from "react";

export const Footer = () => {
  let userLang = navigator.language || navigator.userLanguage;
  let terms_url =
    userLang == "en"
      ? "https://policies.todolegal.app/terms/en"
      : "https://policies.todolegal.app/terms/";

  let privacy_url =
    userLang == "en"
      ? "https://policies.todolegal.app/privacy/en"
      : "https://policies.todolegal.app/privacy/";

  return (
    <footer className="mb-4 footer-container">
      <hr className="mt-6 mb-2" />
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <p>
              <span className="icon-text">
                <span className="icon">&copy;</span>
                <span>2019-2024 by TodoLegal</span>
              </span>
            </p>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <p className="footer-p">
              <span>
                <a href={terms_url}>Terminos</a> y{" "}
                <a href={privacy_url}>Privacidad</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
