import { types } from '../types/types';

const initialState = {
    pending: false,
    documents: [],
    // hasMore: true,
    error: null,
    documentsCount: 0,
    downloads: 0
}

export const feedReducer = ( state = initialState, actions ) => {
    
    switch ( actions.type ) {
        case types.FETCH_DOCUMENTS_PENDING:
            return {
                ...state,
                pending: true
            };
        
        case types.FETCH_DOCUMENTS_SUCCESS:
            return {
                ...state,
                pending: false,
                documents: actions.payload.documents,
                documentsCount: actions.payload.count,
                downloads: actions.payload.downloads
            };

        case types.FETCH_DOCUMENTS_ERROR:
            return{
                ...state,
                pending: false,
                error: actions.payload
            };

        case types.ADD_DOWNLOAD_COUNTER_FEED:
            return {
                ...state,
                downloads: actions.payload.downloads+1
            };

        default:
            return state;
    }
}