import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";
import { DashBoardRoutes } from './DashBoardRoutes';
import TagManager from 'react-gtm-module';
 
const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`
}

TagManager.initialize(tagManagerArgs);

export const AppRouter = () => {
    return (
        <Router>
            <div>
            <Routes>
                {/* En caso de que haya un login u otra pantalla que necesite no llevar el estilo de las otras, deberia ir aca.
                    Tambien aca si necesitamos un estilo global que abarque el login.
                */}
                <Route path="*" element={ <DashBoardRoutes />} />
            </Routes>
            </div>
        </Router>
    )
}
