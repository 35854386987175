import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentById } from "../../state/actions/document";
import { ShareAndDownload } from "./ShareAndDownload";
import { RelatedDocuments } from "./RelatedDocuments";
import { DocumentHeader } from "./DocumentHeader";
import { Banner } from "../wall/Banner";
import { getDocumentTitle } from "../../helpers/getDocumentTitle";
import { ProgressBar } from "../ui_elements/ProgressBar";
import 'animate.css';
import { Wall } from "../wall/Wall";

export const DocScreen = () => {
  const { documentId } = useParams();

  const [docData, setDocData] = useState({
    data: [],
    loading: true,
    documentIssuer: null
  });

  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);
  const { pending, documentDetails, issuer, documentTags, relatedDocuments } = useSelector((state) => state.document);

  const { data, loading } = docData;


  useEffect(()=> {
    setDocData({
      data: documentDetails,
      loading: pending,
      documentIssuer: issuer
    })
  }, [documentDetails, pending, issuer]);

  useEffect(() => {
    dispatch(getDocumentById(documentId, token));
  }, [documentId]);


  const {id, name, url, publication_date, publication_number, document_type, description, short_description, file, issue_id, alternative_issue_id} = data;

  const { documentIssuer } = docData;

  const availableDescription = (description == "" || description == null) ? short_description : description;

  return (
    <section className="doc-screen">
      <div className="container ml-3 mr-3">
        { !loading ?
            
            (
              <div className="document-details mt-3 animate__animated animate__fadeIn">
                <DocumentHeader
                  name={name}
                  combinedName={ getDocumentTitle( name, issue_id, document_type, alternative_issue_id) }
                  issuer={ documentIssuer }
                  publicationDate={publication_date}
                  publicationNumber = {publication_number}
                  description={availableDescription}
                  tags={documentTags}
                  url={url}
                  id={id}
                  document_type={ document_type }
                  issueId={issue_id}
                  alternativeIssueId = { alternative_issue_id }
                 />
                <ShareAndDownload 
                  url={url} 
                  file={file} 
                  combinedName={ document_type == "Auto Acordado" ? name : getDocumentTitle(name, issue_id) } 
                  documentId={id}
                />
                <Banner />
                
                <hr className="mt-2 mb-3  has-background-grey-lighter"/> 
                <RelatedDocuments relatedDocuments={relatedDocuments} id={id} />
              </div>
            )
            :
            ( <ProgressBar /> )
        }
      </div>
    </section>
  );
};
