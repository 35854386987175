import React from "react";

export const DeletedDocumentBanner = () => {

  return (
    <>
      <section className="banner-download hero is-small animate__animated animate__fadeIn">
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="subtitle">
              Lo siento. Este documento fue eliminado o substituído por otro, haz la búsqueda en Valid para encontrarlo. 
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
