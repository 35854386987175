import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeToken } from "../../state/actions/auth";
import { logout } from "../../state/actions/user";

export const UserAvatar = ({ firstName, lastName }) => {
  const dispatch = useDispatch();
  const { userType } = useSelector((state) => state.user);

  const handleToggle = (e, isOnBlur) => {
    const dropdown = document.querySelectorAll(".dropdown");
    dropdown.forEach((element) => {
      if( isOnBlur ){
        element.classList.remove("is-active");
      }else{
        element.classList.toggle("is-active");
      }
      
    });
  };

  const handleLogout = (e) => {
    localStorage.removeItem("access_token");
    dispatch(logout());
    dispatch(removeToken());
  };

  const handleItemToggle = (e) => {
    e.preventDefault();
    const dropdown = document.querySelectorAll(".dropdown");
    dropdown.forEach((element) => {
      element.classList.add("is-active");
    });
  };

  return (
    <>
      <div className="dropdown is-right">
        <div className="dropdown-trigger">
          <button
            className="button is-link user-avatar"
            aria-haspopup="true"
            onBlur={ (e) => handleToggle(e, true) }
            onClick={ (e) => handleToggle(e, false) }
            aria-controls="dropdown-menu3"
          >
            <span>{firstName?.charAt(0) + lastName?.charAt(0)}</span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu3" role="menu">
          <div className="dropdown-content">
            <a onMouseDown={handleItemToggle} className="dropdown-item" href={`${process.env.REACT_APP_TL_BASE_URL}/users/edit`}>
              <span className="icon"> <i className="fa-solid fa-user"></i> </span>
              <span>Tu cuenta</span>
            </a>
              {
                !(userType === "pro") && (
                  <a onMouseDown={handleItemToggle} className="dropdown-item" href={`${process.env.REACT_APP_TL_BASE_URL}/pricing?return_to=${process.env.REACT_APP_VALID_BASE_URL}`}>
                    <span className="icon"><i className="fa-solid fa-bolt"></i></span>
                    <span>Suscríbete</span>
                  </a>
                )
              }
            <Link className="preferences-link dropdown-item" to="/users/preferences" onMouseDown={handleItemToggle}>
              <span className="icon"><i className="fa-solid fa-envelope"></i></span>
              <span>Notificaciones</span>
            </Link>
            <a href={`${process.env.REACT_APP_TL_BASE_URL}/home`} onMouseDown={handleItemToggle} className="dropdown-item">
                <img  src="/assets/tl_icon_white.svg" alt="Ir a TodoLegal" className="menu-logo todolegal-white" />
                <img  src="/assets/tl_icon_black.svg" alt="Ir a TodoLegal" className="menu-logo todolegal-black" />
                <span className="ml-1">TodoLegal</span>
            </a>
            <a href={`${process.env.REACT_APP_LEY_ABIERTA_BASE_URL}`} onMouseDown={handleItemToggle} className="dropdown-item">
                <img  src="/assets/ley_abierta_icon_white.png" alt="Ir a Ley Abierta" className="menu-logo leyabierta-white" />
                <img  src="/assets/ley_abierta_icon_black.svg" alt="Ir a Ley Abierta" className="menu-logo leyabierta-black" />
                <span>Ley Abierta</span>
            </a>
            <a href="#" onMouseDown={handleLogout} className="dropdown-item">
                <span className="icon"><i className="fa fa-sign-out-alt"></i></span>
                <span className="ml-1">Cerrar Sesión</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
