import React, { useEffect, useRef } from 'react';

/*
Este custom hook simula el componentDidUpdate de un componenten de clase, me es util para cuando quiero evitar el primer render del useEffect y solo 
ejecutarlo cuando alguna de sus dependencias cambia. useRef es la clave para que funcione.
*/


export const useDidUpdate = ( func, dependencies) => {
    
    const firstUpdate = useRef(true);

    useEffect(() => {
        if( firstUpdate.current ){
            firstUpdate.current = false;
            return;
        }else{
            func();
        }
        
    }, dependencies)
}
