import { useSelector } from 'react-redux';
import { DownloadButton } from '../ui_elements/DownloadButton';
import { ShareButton } from '../ui_elements/ShareButton';

export const ShareAndDownload = ({url, file, combinedName, documentId}) => {
    
    const { canAccess } = useSelector((state) => state.document);
    const { downloads } = useSelector( state => state.document );
    const { isUserConfirmed, userType } = useSelector((state) => state.user);

    // const oAuthSignUp = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&go_to_signup=true&state=${redirect_state}`;

    return (
        <div className="columns has-text-centered">

            <div className="column is-desktop is-one-third buttons-container">

                <div className="field is-grouped is-grouped mt-2">
                    <p className="control download-container">
                        <DownloadButton file={file} combinedName={combinedName} documentId={documentId} downloads={downloads} />
                    </p>
                    <p className="control">
                        <ShareButton />
                    </p>
                    <p className="control">
                        <span className="twitter-share-container">
                            <a href={`https://twitter.com/intent/tweet?text=${combinedName}&url=${window.location.href}`} className="twitter-share-button" data-show-count="false">
                                <img className="share-icon" src="/assets/icons/twitter.png" />
                            </a>
                        </span>
                    </p>
                </div>

            </div>
            
        </div>
    )
}