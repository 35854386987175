import React from 'react'
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ children }) => {

  // const { userType } = useSelector( state => state.user );
  const { token } = useSelector( state => state.auth );
  const oAuthLogin = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&state=/users/preferences`;

  if (token == null){
    window.location.href = oAuthLogin;
    return;
  }
  
  return children;
}
