import { types } from '../types/types';
import axios from 'axios';


export const fetchDocumentPending = ( ) => ({
    type: types.FETCH_DOCUMENTS_PENDING
})

export const fetchDocumentSuccess = ( documents, count, downloads ) => ({
    type: types.FETCH_DOCUMENTS_SUCCESS,
    payload: {
        documents,
        count,
        downloads
    }
})

export const fetchDocumentError = ( error ) => ({
    type: types.FETCH_DOCUMENTS_ERROR,
    payload: error
})


export const fetchDocuments = ( url, token ) =>{
    let accessToken = "";
    if (token !== null) accessToken = `&access_token=${token}`;

    return( dispatch ) => {

        dispatch( fetchDocumentPending() );

        axios.get( `${url}${accessToken}` )
            .then( response  => {
                const { documents } = response.data;
                const {  count } = response.data;
                const { downloads } = response.data;
                dispatch( fetchDocumentSuccess( documents, count, downloads ));
                
            })
            .catch( ( error ) => {

                // console.log( error );
                dispatch( fetchDocumentError( error ) );

            }); 
    }

}

export const addDownloadCounterFeed = (downloads) => ({
    type: types.ADD_DOWNLOAD_COUNTER_FEED,
    payload: {
      downloads: downloads, 
    },
  });

