import { types } from '../types/types';
import axios from 'axios';
import { baseUrl } from '../reducers/queryReducer';

export const getUserSuccess = ( user ) => ({
    type: types.GET_USER_SUCCESS,
    payload: {
        accountInfo: user.accountInfo,
        userType: user.userType,
        userPermission: user.userPermission,
        isUserConfirmed: user.isUserConfirmed,
        canAccess: user.canAccess,
        remainingTrialDays: user.remainingTrialDays,
        isTokenActive: true
    }
})

export const getUserPending = ( ) => ({
    type: types.GET_USER_PENDING
})

export const getUserError = ( error ) => ({
    type: types.GET_USER_ERROR,
    payload: error
})

export const userPreferencesSuccess = ( user ) => ({
    type: types.GET_USER_PREFERENCES_SUCCESS,
    payload: {
        userPreferences: user.userPreferences
    }
})

export const userPreferencesPending = ( ) => ({
    type: types.GET_USER_PREFERENCES_PENDING
})

export const userPreferencesError = ( error ) => ({
    type: types.GET_USER_PREFERENCES_ERROR,
    payload: error
})

export const getUser = ( token ) => {
    return ( dispatch ) => {
        dispatch( getUserPending() );
        axios.get(`${baseUrl}/me`, {
                params: {
                    access_token: token
                }
            })
            .then( response => {
                const user = {
                    accountInfo: response.data.user,
                    userType: response.data.user_type,
                    userPermission: response.data.user_permission,
                    isUserConfirmed: response.data.confirmed_user,
                    canAccess: response.data.can_access,
                    remainingTrialDays: response.data.remaining_free_trial
                }
                dispatch( getUserSuccess( user) );
            })
            .catch( error  => {
                console.log( error );
                dispatch( getUserError( error ) );
                dispatch(logout());
                localStorage.removeItem('access_token');
            })

    }
}

export const getUserPreferences = ( token ) => {
    return ( dispatch ) => {
        dispatch( userPreferencesPending() );
        axios.get(`${baseUrl}/users_preferences`, {
            params: {
                access_token: token
            }
        })
        .then( response => {
            const user = {
                userPreferences: response.data.user_preferences 
            }
            dispatch( userPreferencesSuccess(user) );
        })
        .catch( error => {
            dispatch( userPreferencesError(error) );
        })
    }
}

export const saveUserPreferences = ( selectedTagsIds, selectedFrequency, token) => {
    
    let accessToken = "";
    if (token !== null) accessToken = `&access_token=${token}`;

    let requestUrl = `${baseUrl}/users_preferences`;

    if( selectedTagsIds.length > 0){
        selectedTagsIds.forEach((element, index) => {
            if( index > 0){
                requestUrl = `${requestUrl}&tags_id[]=${element}`;
            }else{
                requestUrl = `${requestUrl}?tags_id[]=${element}`;
            }  

        });

        if( selectedFrequency ){
            requestUrl = `${requestUrl}&mail_frequency=${selectedFrequency}`;
        }
        requestUrl = `${requestUrl}&access_token=${token}`;
    }else if (selectedFrequency){
        requestUrl = `${requestUrl}?mail_frequency=${selectedFrequency}&access_token=${token}`;
    }else{
        requestUrl = `${requestUrl}?access_token=${token}`;
    }

    return ( dispatch ) => {
        axios.patch( requestUrl )
        .then( (response) => {
            if ( response.status === 200 ){
                dispatch( setPreferencesSaveState(true) )
                dispatch( setUserPreferences(selectedTagsIds, selectedFrequency) );
            }
                
        })
        .catch( error => {
            console.log( error );
        })
    }
}

const setUserPreferences = (tags, emailFrequency) => {
    return {
        type: types.SET_USER_PREFERENCES,
        payload:{
            selectedTags: tags,
            emailFrequency: emailFrequency,
        }
    }
}

export const setNotificationsActivation = (activationState) => {
    return{
        type: types.SET_NOTIFICATIONS_ACTIVATION,
        payload: activationState
    }
}

export const saveNotificationsActivation = (activation, token) => {
    let accessToken = "";
    if (token !== null) accessToken = `?access_token=${token}`;

    return ( dispatch ) => {
        axios.patch(`${baseUrl}/users_preferences/deactivate_notifications${accessToken}`)
        .then( response => {
            if ( response.status === 200 ){
                dispatch( setNotificationsActivation(activation) );
            }
        })
        .catch( error => {
            console.log( error );
        })
    }
}

export const setPreferencesSaveState = ( savedState ) => ({
    type: types.SAVE_USER_PREFERENCES,
    payload: savedState
})

export const logout = () => ({
    type: types.LOGOUT
});