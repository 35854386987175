import { baseUrl } from '../reducers/queryReducer';
import { types } from '../types/types';

export const withQuery = ( query, token ) => {
    let accessToken = "";
    if (token !== null) accessToken = `&access_token=${token}`;

    return {
        type: types.WITH_QUERY,
        payload:{
            url: `${baseUrl}/documents?query=${query}&limit=15${accessToken}`,
            query
        } 
    }
}

export const applyFilters = ( queryValue, startDate, endDate, newTags, token ) => {

    let accessToken = "";
    if (token !== null) accessToken = `&access_token=${token}`;

    let urlWithFilters =  `${baseUrl}/documents`;

    if( queryValue != ""){

        urlWithFilters = `${urlWithFilters}?query=${queryValue}`
        if( newTags.length > 0 ){
            newTags.forEach( element => {
                urlWithFilters = `${urlWithFilters}&tags[]=${element}`;
            });
        }
        if( (startDate != null && endDate != null) && (startDate != "" && endDate != "") ){
            urlWithFilters = `${urlWithFilters}&from=${startDate}&to=${endDate}`;
        }
    }else if ( newTags.length > 0 ){
        urlWithFilters = `${urlWithFilters}?tags[]=${newTags[0]}`;
        newTags.forEach( ( element, index) => {
            if( index > 0 )
                urlWithFilters = `${urlWithFilters}&tags[]=${element}`;
        });

        if( (startDate != null && endDate != null) && (startDate != "" && endDate != "") ){
            urlWithFilters = `${urlWithFilters}&from=${startDate}&to=${endDate}`;
        }
    }else if ( (startDate != null && endDate != null) && (startDate != "" && endDate != "") ){
        urlWithFilters = `${urlWithFilters}?from=${startDate}&to=${endDate}`;
    }

    urlWithFilters = `${urlWithFilters}&limit=15${accessToken}`;

    return{
        type: types.WITH_TAGS,
        payload:{
            url: urlWithFilters,
            tags: newTags,
            from: startDate,
            to: endDate
        } 
    }

}

export const currentUrlWithLimit = ( currentUrl, limit, token ) => {
    let accessToken = "";
    if (token !== null) accessToken = `&access_token=${token}`;

    const res = currentUrl.split(/&limit=[0-9]+/g);
    currentUrl = res[0];

    return{
        type: types.WITH_CURRENT_URL_AND_LIMIT,
        payload: {
            url: `${currentUrl}&limit=${limit}${accessToken}`,
            counter: limit
        }
    }
}

export const resetCurrentUrl = () => ({
    type: types.RESET_CURRENT_URL
})

export const inCrementCounterState = ( num ) =>({
    type: types.INCREMENT_COUNTER_STATE,
    payload: num
})

export const resetCounterState = () =>({
    type: types.RESET_COUNTER_STATE
})

export const resetQueryStateValue = () => ({
    type: types.RESET_QUERY_VALUE
})

export const resetTagFilters = () => ({
    type: types.RESET_TAG_FILTERS
})

export const setStartDate = ( date ) => ({
    type: types.SET_FILTER_START_DATE,
    payload: date
})

export const setEndDate = ( date ) => ({
    type: types.SET_FILTER_END_DATE,
    payload: date
})

export const resetDateFilter = () => ({
    type: types.RESET_DATE_FILTER
})

export const setTagFilter = ( tag, tagType ) => {

    let type = "";

    switch (tagType) {
        case "institution":
            type = types.SET_INSTITUTION_TAG;
            break;
        case "actType":
            type = types.SET_ACT_TYPE_TAG;
            break;
        default:
            type = types.SET_TOPIC_TAG;
            break;
    }

    return {
        type: type,
        payload: tag
    }
}

export const resetTag = ( tagType ) => {

    let type = "";

    switch (tagType) {
        case "institution":
            type = types.RESET_INSTITUTION_TAG;
            break;
        case "actType":
            type = types.RESET_ACT_TYPE_TAG;
            break;
        default:
            type = types.RESET_TOPIC_TAG;
            break;
    }

    return {
        type: type
    }
}

export const resetAllTags = () => ({
    type: types.RESET_TAG_FILTERS
})

