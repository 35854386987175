import { types } from "../types/types";

const initialState = {
  pending: false,
  accountInfo: {},
  userPreferences: {},
  userPreferencesPending: false,
  userPreferencesError: null,
  preferencesSaved: false,
  selectedPreferenceTags: [],
  selectedEmailrequency: 0,
  activeNotifications: false,
  isUserConfirmed: true,
  userType: "not logged",
  userPermission: "",
  canAccess: false,
  remainingTrialDays: 0,
  isTokenActive: false,
  error: null,
};

export const userReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case types.GET_USER_PENDING:
      return {
        ...state,
        pending: true,
      };

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        accountInfo: actions.payload.accountInfo,
        userType: actions.payload.userType,
        userPermission: actions.payload.userPermission,
        isUserConfirmed: actions.payload.isUserConfirmed,
        canAccess: actions.payload.canAccess,
        remainingTrialDays: actions.payload.remainingTrialDays,
        isTokenActive: actions.payload.isTokenActive,
      };

    case types.LOGOUT:
      return {
        ...state,
        isTokenActive: false,
        userType: "not logged",
        userPermission: "",
        isUserConfirmed: false,
        canAccess: false,
        remainingTrialDays: 0,
        accountInfo: {},
        userPreferences: {},
        activeNotifications: false
      };

    case types.GET_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.payload,
      };

    case types.GET_USER_PREFERENCES_SUCCESS:
      return{
        ...state,
        userPreferences: actions.payload.userPreferences,
        userPreferencesPending: false
      };

      case types.GET_USER_PREFERENCES_PENDING:
      return{
        ...state,
        userPreferencesPending: true
      };

      case types.GET_USER_PREFERENCES_ERROR:
      return {
        ...state,
        pending: false,
        userPreferencesError: actions.payload,
      };

      case types.SAVE_USER_PREFERENCES:
      return {
        ...state,
        preferencesSaved: actions.payload
      }

      case types.SET_USER_PREFERENCES:
        return{
          ...state,
          selectedPreferenceTags: actions.payload.selectedTags,
          selectedEmailrequency: actions.payload.emailFrequency
        }

      case types.SET_NOTIFICATIONS_ACTIVATION:
        return{
          ...state,
          activeNotifications: actions.payload
        }
    default:
      return state;
  }
};
