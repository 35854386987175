import React, { useLayoutEffect, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getParamValue } from '../../helpers/getParamValue';
import TagManager from 'react-gtm-module';

export const ConfirmEmailWall = ({ activeWall = true}) => {

  const { isUserConfirmed, userType } = useSelector((state) => state.user);
  const [showLoader, setShowLoader] = useState(false);

  let location = useLocation();
  let redirect_url = process.env.REACT_APP_VALID_BASE_URL;
  redirect_url = `${redirect_url}${location.pathname}`;
  const isActive =  !isUserConfirmed && activeWall ? "is-active" : ""
  const confirmationEmailSent = getParamValue("confirmation_email_sent");

  const whatsAppLink = "https://wa.me/50494714842";

  useLayoutEffect(() => {
    const handleBackButton = () => {
      setShowLoader(false);
    };

    window.addEventListener('popstate', handleBackButton);
    window.addEventListener('pageshow', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('pageshow', handleBackButton);
    };
  }, []);

  useEffect (() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';
  
    // Re-enable scrolling when component unmounts
    return () => { document.body.style.overflow = 'unset'; };
  }, []); // Empty array ensures effect is only run on mount and unmount

  const handleBackUnconfirmed = (e) => {
    const menu = document.getElementsByClassName('wall-unconfirmed');
    e.preventDefault();

    for (const element of menu) {
      element.classList.toggle('is-active');
    }
  };


  return (
    <>
      <div className={`modal confirm-email-wall wall-unconfirmed ${isActive} animate__animated animate__fadeIn`}>
          <div className="row logo-row mb-4">
            <img  src="/assets/tl_icon_black.svg" alt="TodoLegal logo" className="todolegal-logo mr-6" />
            <img  src="/assets/valid_icon_black.svg" alt="TodoLegal logo" className="todolegal-logo" />
          </div>
        <div className="modal-background white-background"></div>
        <div className="modal-content">
          <div className="box is-dark">
            <article className="wall-modal">
              <h3 className="is-title-modal"><strong>Confirma tu correo</strong></h3>
              { confirmationEmailSent === null ?
                (
                  <>
                      <p className="is-content-text-modal">Para poder descargar documentos debes <strong>activar</strong> tu cuenta con el correo que te llegó a tu bandeja de entrada.</p>
                      <p className="is-content-text-modal"> 
                        Si no lo recibiste, puedes enviarte el correo nuevamente o puedes contactarte con uno de nuestros agentes.
                      </p>
                  </>
                )
                :
                (
                  <>
                     <p className="is-content-text-modal"> 
                        Correo de confirmación enviado exitosamente, por favor revisa tu correo.
                      </p>
                  </>
                )
              }
             
            </article>

            <div className="columns mt-4">
              <div className="column">
                <div className="buttons modal-buttons is-justify-content-center">
                  <div className="field is-grouped">
                    <p className="control">
                      <a 
                        href={`${process.env.REACT_APP_TL_HOME}/send_confirmation_email?redirect_to=${redirect_url}`} 
                        className="button mr-2 is-primary"
                        onClick={(e) => handleTagManagerEvent(e, false)}
                      >
                        Reenviar correo
                      </a>
                    </p>
                    <p className="control">
                      <a href={whatsAppLink} target="_blank" className="button is-primary is-light">WhatsApp</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
};
