import React from 'react';
import { Link } from 'react-router-dom';
import { removeInstitutionTags } from '../../helpers/removeInstitutionTags';
import { getDocumentTitle } from '../../helpers/getDocumentTitle';
import { getDocumentType } from '../../helpers/getDocumentType';
import { getUrl } from '../../helpers/getUrl';
import TagsList from './TagsList';
import { useSelector } from 'react-redux';
import { DownloadButton } from './DownloadButton';
import { getDateInWords } from '../../helpers/getDateInWords';
import Highlighter from "react-highlight-words";
import { findChunks } from '../../helpers/findChunks';
import { ShareButton } from './ShareButton';
import parse from 'html-react-parser';
import EditButton from './EditButton';



export const DocumentCard = React.forwardRef (({id, name, publication_date, publication_number, url="", issuer, issue_id, alternative_issue_id, tags, description, short_description, document_type, file, can_access, isFeed=false}, ref) => {
    
    
    const { queryValue } = useSelector( state => state.query );
    const { downloads } = useSelector( state => state.feed)
    const { userPermission, canAccess } = useSelector( state => state.user );
    
    let shortenedDescription = "";
    if( short_description == null && description?.length > 0){
        shortenedDescription  = description.substring(0, 120);
        shortenedDescription = shortenedDescription.concat(...shortenedDescription, '...');
    }else if(short_description?.length > 0){
        shortenedDescription = short_description;
    }

    // if user can not access documents, it toggles the "is-active" class on the "wall" component.
    const handleOnClick = (e) => {
        if (!canAccess) {
            const menu = document.getElementsByClassName('wall');
            e.preventDefault();

            for (const element of menu) {
                element.classList.toggle('is-active');
            }
        }
    }

    return (
    
            
                <article className="card document-card" ref={ ref }>
                    <span name="document-date" hidden> { publication_date } </span>
                    <div className="card-content">
                        <div className="columns is-mobile mb-0">
                            <div className="column">
                                <Link 
                                    className="is-link" 
                                    to={ `/${ getDocumentType( document_type ) }/honduras/${ getUrl( url, issue_id, name ) }/${ id }` }
                                    onClick={handleOnClick}
                                >
                                
                                    { document_type == "Auto Acordado" ? 
                                        (
                                             <>
                                                <div className="card-title">
                                                    <Highlighter
                                                        highlightClassName="is-title-document mb-2"
                                                        unhighlightClassName="is-title-document mb-2"
                                                        searchWords={[queryValue]}
                                                        autoEscape={true}
                                                        textToHighlight={ `Auto Acordado ${issue_id}` }
                                                    />
                                                    { userPermission != "" && <EditButton link={`${process.env.REACT_APP_TL_BASE_URL}/documents/${id}/edit`}/> }
                                                </div>
                                                { alternative_issue_id && 
                                                        (
                                                            <>
                                                                <Highlighter
                                                                    highlightClassName="is-title-document mb-2"
                                                                    unhighlightClassName="is-title-document mb-2"
                                                                    searchWords={[queryValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={ alternative_issue_id }
                                                                />
                                                                <br/>
                                                            </>
                                                        )
                                                }
                                                
                                            </>
                                        
                                        )
                                        :
                                        (
                                            <div className="card-title">
                                                <Highlighter
                                                    highlightClassName="is-title-document mb-2"
                                                    unhighlightClassName="is-title-document mb-2"
                                                    searchWords={[queryValue]}
                                                    autoEscape={true}
                                                    textToHighlight={ getDocumentTitle(name, issue_id, document_type, alternative_issue_id) }
                                                />
                                                { userPermission != "" && <EditButton link={`${process.env.REACT_APP_TL_BASE_URL}/documents/${id}/edit`}/> }
                                            </div>
                                        )
                                
                                    }
                                    
                                    <div className="content">
                                        {
                                            document_type && 
                                            (
                                                <>
                                                    <p className="institution-tags mb-0">
                                                        <i className="far fa-folder document-card-icon"></i>
                                                        <span className="mr-2">{ getDocumentType( document_type, true ) }</span>
                                                        {

                                                            document_type == "Auto Acordado" && 
                                                            (
                                                                <>
                                                                    <i className="far fa-folder document-card-icon"></i>
                                                                    <span className="mr-2">Cicular</span>
                                                                </>
                                                            )
                                                        }
                                                    </p>
                                                </>
                                            )
                                        }
                                        <p className="institution-tags mb-0">
                                            { !!issuer && 
                                                (
                                                    <>
                                                        <img className="document-card-icon" src="/assets/icons/Institution.svg" />
                                                        <span className="mr-2">{issuer}</span>
                                                    </>
                                                ) 
                                            }
                                        </p>
                                        <p className="institution-tags mb-0">
                                            <img className="document-card-icon" src="/assets/icons/Calendar.svg" />
                                            <span className="mr-2"> { getDateInWords(publication_date) } </span>
                                        </p>
                                        {
                                            publication_number && (
                                                <p className="institution-tags mb-0">
                                                    <img className="document-card-icon" src="/assets/icons/Gazette.svg" />
                                                    Gaceta No. { (publication_number != "" && publication_number != null) && (`${publication_number}` ) }
                                                </p>
                                            ) 
                                        }
                                        
                                        <section className="card-secondary-tags mb-3">
                                        {
                                            //checks if there are tags available for this document
                                            tags && <TagsList tagsArray = { removeInstitutionTags(tags).slice(0, 4) }/>
                                        }
                                        </section>
                                        <Highlighter
                                            highlightClassName="is-content-text"
                                            unhighlightClassName="is-content-text"
                                            searchWords={[queryValue]}
                                            autoEscape={true}
                                            textToHighlight={ shortenedDescription }
                                            findChunks={findChunks}
                                        />
                                    </div>
                                </Link>
                            </div>
                            
                        </div>
                        
                        <div className="columns is-mobile">
                            <div className="column pb-0 pt-0 pl-0">
                                <DownloadButton file={file} combinedName={ document_type == "Auto Acordado" ? name : getDocumentTitle(name, issue_id)} documentId={id} downloads={downloads}/>
                                <ShareButton 
                                    docID={id} 
                                    docURl={`${process.env.REACT_APP_VALID_BASE_URL}/${ getDocumentType( document_type ) }/honduras/${ getUrl( url, issue_id, name ) }/${ id }`} 
                                />
                            </div>
                        </div>
                    </div>
                </article>
        
    )
})
