import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentCard } from '../ui_elements/DocumentCard';

import { useInView } from 'react-intersection-observer';
import { fetchDocuments } from '../../state/actions/feed';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { currentUrlWithLimit, inCrementCounterState } from '../../state/actions/query';
import { ProgressBar } from "../ui_elements/ProgressBar";
import { MailchimpFormContainer } from '../mailchimp/MailchimpFormContainer';
import 'animate.css';
import { SalesCard } from '../ui_elements/SalesCard';
import TagManager from 'react-gtm-module';
import { filterAvisosAndMarcas } from '../../helpers/filterAvisosAndMarcas';


export const DocsList = () => {

    const [docsData, setDocsData] = useState({
        data: null,
        loading: true,
        url: ""
    });

    const { token } = useSelector( state => state.auth );
    const { userType, canAccess } = useSelector((state) => state.user);
    const [scrollLoadingState, setScrollLoadingState] = useState( false );

    const [ ref, inView, entry ] = useInView({
        threshold: 0.9,
        triggerOnce: true,  // Change to true if you want the event to trigger only once
    });

    const dispatch = useDispatch();

    const { documents, pending, documentsCount} = useSelector( state => state.feed );
    const { counterState, currentUrl, queryValue, fromValue, toValue, tags } = useSelector( state => state.query );

    const{ data, loading, url } = docsData;
    const filtered_data = (data && data.length > 0) && filterAvisosAndMarcas(data, queryValue, fromValue, toValue, tags);
    
    //Actualiza el limit del query, que a su vez actualiza el query del url actual. Esto hace funcionar el infinite scroll. 
    useDidUpdate( () => {
        if( documents.length < documentsCount ){
            dispatch( currentUrlWithLimit( currentUrl, counterState, token ));
            setScrollLoadingState( true );
            
            // If wall is rendered it toggles the "is-active" class in the "wall" component. 
            //This happens when tryin tro scoll down to fetch more documents. It only shows to not pro or free trial users.
            const menu = document.getElementsByClassName('wall');

            for (const element of menu) {
                element.classList.toggle('is-active');
            }
        }
    }, [inView]);
    
    // Fetch documents and increment counter once when the component mounts
    useEffect(() => {
        dispatch(fetchDocuments(currentUrl, token));
        dispatch(inCrementCounterState(counterState + 5));
    }, []); // Empty dependency array ensures this runs only on mount

    // Check condition and fetch documents whenever url or token changes
    useDidUpdate(() => {
        if (canAccess) {
            dispatch(fetchDocuments(url, token));
            dispatch(inCrementCounterState(counterState + 5));

            // Push search event to dataLayer if query value is not empty
            if (queryValue != ""){
                window.dataLayer.push({
                    event: "view_search_results",
                    eventModel: {
                        search_term: queryValue
                    }
                });
            }
        }
    }, [url, token, canAccess]);

    //actualiza el estado de la lista de los documentos con los nuevos documentos que se obtuvieron despues de hacer fetch.
    useEffect(() => {

        setDocsData({
            data: documents,
            loading: pending,
            url: currentUrl
        })
    }, [documents, pending, currentUrl]);

    useEffect(() => {
        setScrollLoadingState( false );
    }, []);

    return (
        <div>
            { loading ? <ProgressBar /> : ( ( filtered_data == null || filtered_data.length === 0) && <p> No se encontraron resultados </p> ) }
            {!!filtered_data && (
                <div className="documents-list animate__animated animate__fadeIn">
                    {
                        filtered_data.map ( (docs, index) => {
                            if( filtered_data.length === index + 1){
                                return(
                                    <DocumentCard 
                                        key={ docs.id }
                                        ref={ ref } 
                                        {...docs}
                                    />
                                );
                                
                            }else{
                                if ( index == 1){
                                    return(
                                        <>
                                            {userType === "not logged" && (
                                                <MailchimpFormContainer />
                                            )}
                                            {
                                                userType === "basic" && (
                                                    <SalesCard />
                                                )
                                            }
                                            <DocumentCard 
                                                key={ docs.id }
                                                {...docs}
                                            />
                                        </>
                                    );
                                    
                                }else{
                                    return(
                                        <DocumentCard 
                                            key={ docs.id }
                                            {...docs}
                                        />
                                    );
                                }
                            }
                        })
                    } 
                </div>
                       
                )
            }  

            { (loading && scrollLoadingState) && <ProgressBar /> }
        </div>
    )
}
