import { Routes, Route, useLocation } from 'react-router-dom';
import { HomePage } from '../components/pages/HomePage';
import { DocumentPage } from '../components/pages/DocumentPage';
import { Navbar } from '../components/ui_elements/Navbar';
import { PreferencesPage } from '../components/pages/PreferencesPage';
import { ProtectedRoute } from '../components/auth/ProtectedRoute';


export const DashBoardRoutes = () => {

    const oAuthLogin = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login`;
    
    return (
        <>
            <Navbar />
            <div className="container">
                <Routes>
                    <Route exact path='/' element={ <HomePage /> } />
                    <Route exact path= '/:documentType/honduras/:url/:documentId' element={ <DocumentPage /> } />
                    <Route 
                        exact path="/users/preferences" 
                        element={
                            <ProtectedRoute>
                                <PreferencesPage/>
                            </ProtectedRoute>  
                        } 
                    />
                    <Route path="*" element={<HomePage />} />
                </Routes>
            </div>
        </>
    )
}
