import { removeAccents } from "./removeAccents";

function removeWhiteSpaces( text ){

    text = text?.trim();
    text = text.replace(/,/g, "");
    text = text.replace(/\s+/g, "-");
    text = text.replace(".", "-");
    text = text.toLowerCase();
    return text;
}


export const getDocumentType = ( documentType, isInDocument=false  ) => {
    let documentTypeName = "gaceta";
    
    //external if is a temporal fix
    if( documentType ){
        if( isInDocument ){
            if (documentType == "Ninguno"){
                documentTypeName = "Sección de Gaceta"
            }else{
                documentTypeName = documentType;
            }
            
        }else{
            if (documentType == "Ninguno" || documentType == null )
                documentTypeName = "Sección de Gaceta"
            documentTypeName = removeWhiteSpaces( documentType );
            documentTypeName = removeAccents( documentTypeName );
            documentTypeName = documentTypeName
        }
        
    }
    return documentTypeName;
}