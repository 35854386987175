import { types } from '../types/types';

export const baseUrl = process.env.REACT_APP_API_URL;

export const firstRenderUrlValues = `${baseUrl}/documents?offset=0&limit=12`;
export const initialCounter = 15;

const initialState = {
    currentUrl: firstRenderUrlValues,
    queryUrl: '',
    queryValue: '',
    fromValue: null,
    toValue: null,
    tags: [],
    institutionValue: null,
    actTypeValue: null,
    topicValue: null,
    counterState: initialCounter,
}


export const queryReducer = ( state = initialState, actions) => {
    switch (actions.type) {
        case types.WITH_QUERY:
            return {
                ...state,
                queryUrl: actions.payload.url,
                queryValue: actions.payload.query,
                currentUrl: actions.payload.url,
                fromValue: null,
                toValue: null,
                tags: []
            }

        case types.WITH_DATE_FILTER:
            return{
                ...state,
                fromValue: actions.payload.from,
                toValue: actions.payload.to,
                currentUrl: actions.payload.url,
            }
        
        case types.WITH_TAGS:
            return{
                ...state,
                currentUrl: actions.payload.url,
                tags: actions.payload.tags,
                fromValue: actions.payload.from,
                toValue: actions.payload.to,
            }

        case types.WITH_CURRENT_URL_AND_LIMIT:
            return {
                ...state,
                currentUrl: actions.payload.url,
                counterState: actions.payload.counter,
            }

        case types.SET_FILTER_START_DATE:
            return {
                ...state,
                fromValue: actions.payload
            }

        case types.SET_FILTER_END_DATE:
            return {
                ...state,
                toValue: actions.payload
            }

        case types.SET_INSTITUTION_TAG:
            return {
                ...state,
                institutionValue: actions.payload
            }

        case types.SET_ACT_TYPE_TAG:
            return {
                ...state,
                actTypeValue: actions.payload
            }

        case types.SET_TOPIC_TAG:
            return {
                ...state,
                topicValue: actions.payload
            }
        
        case types.INCREMENT_COUNTER_STATE:
            return{
                ...state,
                counterState: actions.payload
            }

        case types.RESET_CURRENT_URL:
            return{
                ...state,
                currentUrl: baseUrl,
            }

        case types.RESET_QUERY_VALUE:
            return{
                ...state,
                queryValue: '',
                tags: [],
                fromValue: '',
                toValue: '',
                currentUrl: firstRenderUrlValues,
                queryUrl: firstRenderUrlValues,
            }

        case types.RESET_COUNTER_STATE:
            return{
                ...state,
                counterState: initialCounter,
            }

        case types.RESET_TAG_FILTERS:
            let empty = true;
            let oldUrl = firstRenderUrlValues;
            if( state.queryValue != ""){
                empty = false;
                oldUrl = state.queryUrl;
            }
            return{
                ...state,
                tags: [],
                fromValue: null,
                toValue: null,
                institutionValue: null,
                actTypeValue: null,
                topicValue: null,
                currentUrl: oldUrl,
            }

        case types.RESET_DATE_FILTER:
            return{
                ...state,
                fromValue: null,
                toValue: null
            }

        case types.RESET_INSTITUTION_TAG:
            return{
                ...state,
                institutionValue: null,
            }

        case types.RESET_ACT_TYPE_TAG:
            return{
                ...state,
                actTypeValue: null,
            }

        case types.RESET_TOPIC_TAG:
            return{
                ...state,
                topicValue: null,
            }
        
        default:
            return state;
    }
}
