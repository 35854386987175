export const filterAvisosAndMarcas = (docs, query, fromValue, toValue, tags) => {

  const possibleText = ["avisos legales", "aviso legal", "avisos", "marcas de fabrica", "marcas de fábrica", "marcas de fábricas", "marcas de fabricas", "marca de fabrica", "marcas"];
  query = query && query.length > 0;
  const filteredData = docs.filter( (doc) => {
    let name = (doc.name && doc.name.length > 0) ? doc.name.toLowerCase() : "nothing";
    return !possibleText.includes(name) || query || fromValue || toValue ;
  });

  return filteredData;
}
