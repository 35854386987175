import { removeAccents } from "./removeAccents";

function removeWhiteSpaces( text ){

    text = text?.trim();
    text = text.replace(/,/g, "");
    text = text.replace(/\s+/g, "-");
    text = text.replace(".", "-");
    text = text.toLowerCase();
    return text;
}

export const getUrl = ( url, issueId, documentName ) => {
    let newUrl = url;

    if( url == "" || url == null){
        if( documentName != "" && documentName != null ){
            documentName = removeAccents( documentName );
            newUrl = `${ removeWhiteSpaces( documentName) }`;
        }else if( issueId != "" && issueId != null ){
            issueId = removeAccents( issueId );
            newUrl = `${ removeWhiteSpaces( issueId) }`;
        }else{
            newUrl = "gaceta";
        }
    }

    return newUrl;
}