import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../helpers/mixpanel";
import { useDidUpdate } from "../../hooks/useDidUpdate";
import { getUser } from "../../state/actions/user";
import { LoggedOutNavbar } from "./LoggedOutNavbar";
import { UserAvatar } from "./UserAvatar";


export const Navbar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    isAccessTokenActive: false,
  });

  const { token, oAuthState } = useSelector((state) => state.auth);
  const { pending, accountInfo, userType, isTokenActive } = useSelector(
    (state) => state.user
  );
  const { showSessionWarning } = useSelector( state => state.ui);

  const { id, first_name, last_name, email } = userType ? accountInfo : "" ;

  useEffect(() => {
    if( token ){
      dispatch(getUser(token));
      if (oAuthState != "")
        navigate(oAuthState);
    }
  }, [token]);

  useDidUpdate(() => {
    setUserInfo({
      firstName: first_name,
      lastName: last_name,
      isAccessTokenActive: isTokenActive,
    });
    //Identify user in mixpanel after login
    Mixpanel.identify(id)
    if (email == "carlosvilla00896@gmail.com")
      Mixpanel.alias('carlosvilla00896@gmail.com');
    
  }, [first_name, last_name, isTokenActive]);

  const { firstName, lastName, isAccessTokenActive } = userInfo;


  return (
    <>
      {pending === false && userType === 'not logged'?
        (
          <LoggedOutNavbar />
        )
        :
        (
          <nav
            className="navbar main--navbar"
            role="navigation"
            aria-label="main navigation"
            id="validNavbar"
          >
            <div className="navbar-brand">
                <div className="navbar-item">
                  <a className="" href={`${process.env.REACT_APP_TL_BASE_URL}`}>
                    <img className="logo-icon" src="/assets/tl_icon_white.svg" />
                  </a>
                  <img  src="/assets/white_line.png" alt="divider" className="vertical-line" />
                  <a className="navbar-item valid-logo-item" href="/">
                    <img className="logo-letter" src="/assets/logo-valid-letras.png" />
                  </a>
                </div>
                
                <section className="is-hidden-mobile is-flex-tablet is-hidden-desktop">
                  <div className="navbar-item">
                    { !pending && <UserAvatar firstName={firstName} lastName={lastName} />}
                  </div>
                </section>
    
                <div className="is-hidden-tablet is-flex-mobile">
                  <div className="navbar-item suscribe-item">
                    {
                      (!pending && !(userType === "pro")) && (
                        <a href={`${process.env.REACT_APP_TL_BASE_URL}/pricing?return_to=${process.env.REACT_APP_VALID_BASE_URL}`}>
                          <span className="icon"><i className="fa-solid fa-bolt"></i></span>
                          Suscríbete
                        </a>
                      )
                    }
                  </div>
                  <div className="navbar-item">
                    { !pending && <UserAvatar firstName={firstName} lastName={lastName} />}
                  </div>
                </div>
              
            </div>
    
            <div id="navbarBasicExample" className="navbar-menu">
              <div className="navbar-end">
                {!pending && (
                  <>
                    {
                      !(userType === "pro") && (
                        <div className="navbar-item">
                          <a href={`${process.env.REACT_APP_TL_BASE_URL}/pricing?return_to=${process.env.REACT_APP_VALID_BASE_URL}`}>
                            <span className="icon"><i className="fa-solid fa-bolt"></i></span>
                            Suscríbete
                          </a>
                        </div>
                      )
                    }
                    <div className="navbar-item">
                      <div className="buttons">
                        <UserAvatar firstName={firstName} lastName={lastName} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </nav>
        )
      }
    </>
  );
};
