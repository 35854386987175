import React, { useCallback } from 'react';
import {copyToClipboard} from '../../helpers/copyToClipboard';

export const ShareButton = ({docID='', docURl=''}) => {

  const buttonRef = useCallback(node => {
    if (node !== null) {
        const btn = document.querySelector(`.copy-to-clipboard${docID}`);
        btn.addEventListener('click', () => {
            copyToClipboard(docURl);
            node.classList.remove("is-danger");
            node.classList.add('is-success');
            node.innerHTML = 'Enlace copiado &nbsp; <i className="fa-solid fa-check"></i>';
            node.blur();
        })
    }
  }, []);

  return (
    <a ref={ buttonRef } className={`copy-to-clipboard${docID} button is-normal is-white`}>
      <img className="share-icon" src="/assets/icons/Share.svg" /><span className='download-title'>Compartir</span>   
    </a>
  )
}
