import React from 'react'
import { selectImageToDisplay } from '../../helpers/selectImageToDisplay';
import { Helmet } from "react-helmet";
import { getDocumentType } from '../../helpers/getDocumentType';
import { getUrl } from '../../helpers/getUrl';

export const Metatags = ({ name, combinedName, publicationDate, description, actTypetag, url, documentId, issuer, documentType, issueId} ) => {
    return (
        <Helmet>
            <title> 
                { `${documentType !="Auto Acordado" ? combinedName : name} ${ (actTypetag != "" && documentType !="Auto Acordado") ? `| ${actTypetag}`: ""} ${ (issuer != "" && issuer !=null) ? `| ${issuer}`: ""}` }
            </title>
            <meta name="description" content={`${description}`} />
            
            <meta property="og:url" content={`${process.env.REACT_APP_VALID_BASE_URL}/${getDocumentType(documentType)}/honduras/${getUrl( url, issueId, name )}/${documentId}`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="valid.todolegal.app" />
            <meta property="og:title" content={` ${ actTypetag != "" ? `${actTypetag}`: ""} ${combinedName} ${ (issuer != "" && issuer !=null) ? `${issuer}`: ""} ${publicationDate}`} />
            <meta property="og:description" content={`${description}`} />
            <meta property="og:locale" content="es_LA" />
            <meta property="og:image" content={ `${process.env.REACT_APP_VALID_BASE_URL}/assets/${ selectImageToDisplay( documentType ) }.png` } />


            <meta name="twitter:card" content="summary" />
            {/* <meta name="twitter:site" content="test.valid.todolegal" /> */}
            <meta property="twitter:title" content={` ${ actTypetag != "" ? `${actTypetag}`: ""} ${combinedName} ${ (issuer != "" && issuer !=null) ? `${issuer}`: ""} ${publicationDate}`} />
            <meta name="twitter:description" content={`${description}`} />
            <meta name="twitter:image" content={ `${process.env.REACT_APP_VALID_BASE_URL}/assets/${ selectImageToDisplay( documentType ) }.png` } />
            <meta property="twitter:locale" content="es_LA" />
            <meta name="twitter:image:alt" content={ documentType }  />
        </Helmet>
    )
}
