import { types } from '../types/types';
import { getUser } from "../../state/actions/user";
import axios from 'axios';


export const getTokenSuccess = ( token ) => ({
    type: types.GET_TOKEN_SUCCESS,
    payload: token
})

export const getTokenError = ( error ) => ({
    type: types.GET_TOKEN_ERROR,
    payload: error
})

export const removeToken = () => ({
    type: types.REMOVE_TOKEN,
    payload: null
})


export const getCode = ( code, state ) => ({
    type: types.GET_CODE_SUCCESS,
    payload: {
        code: code,
        oAuthState: state, 
    },
})

export const getToken = ( hostServerUrl, client_id, client_secret, redirect_uri, code ) => {
    return ( dispatch ) => {
        axios.post( hostServerUrl, {
            client_id,
            client_secret,
            redirect_uri,
            code,
            grant_type: "authorization_code", 
        })
        .then( (response) => {
            localStorage.setItem("access_token", response.data.access_token);
            dispatch(getTokenSuccess(response.data.access_token));
        })
        .catch( error => {
            console.log( error );
            dispatch( getTokenError( error ));
        })
    }
}

export const removeOAuthState = () => ({
    
    
})