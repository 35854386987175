import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const SalesCard = () => {

  const { canAccess } = useSelector((state) => state.user);

  let location = useLocation();

  let redirect_url = process.env.REACT_APP_VALID_BASE_URL;
  redirect_url = `${redirect_url}${location.pathname}`;

  const whatsAppLink = "https://wa.me/50494714842";
  const handleHideComponent = ( e ) => {
    document.getElementById("salesCard").classList.add("mailing-card-hidden");
  }

  const showSalesCard = !canAccess 

  return (
    <>
      { showSalesCard && 
        (
          <article className="card mailing-card mb-4" id="salesCard">
            <div className="card-content">
              <div className="columns is-mobile">
                <div className="column">
                  <button className="delete is-medium" onClick={handleHideComponent}></button>
                  
                  <h1 className="is-title-document mb-1">Tu prueba gratuita ha expirado</h1>
                  <div className="content">
                    <p className="mb-1">Para seguir utilizando nuestro servicio de forma ilimitada y estar informado con el contenido legal que necesitas, 
                    adquiere uno de <a className="is-blue-link" href={`${process.env.REACT_APP_TL_BASE_URL}/pricing?return_to=${redirect_url}`}>nuestros planes </a>o contacta un agente de ventas a través de WhatsApp haciendo<a className="is-blue-link" href={whatsAppLink} target="_blank"> click aquí</a>.</p>
                  </div>

                </div>
              </div>
            </div>
          </article>
        )
      }
    </>
   
  )
}
