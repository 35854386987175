import { getDateInWords } from "./getDateInWords";

export const getCardsDate = (cards, dateContainer, firstCardDate) => {
    let date = "";

    date = getDateInWords(firstCardDate);

    if( date != "" ) {
        dateContainer.innerHTML = date;
    }
    

    cards?.forEach( element => {
        if( element.offsetTop != 0 && element.offsetTop - 1 <= window.pageYOffset ){
            if(dateContainer.innerHTML != element.firstChild.innerHTML){
                date = getDateInWords( element.firstChild.innerHTML );
                dateContainer.innerHTML = date;
            }
        }
    });

}