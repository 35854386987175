import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addDownloadCounterFeed } from '../../state/actions/feed';
import { addDownloadCounter } from '../../state/actions/document';
import { Mixpanel } from '../../helpers/mixpanel';
import TagManager from 'react-gtm-module';

export const DownloadButton = ( {file, combinedName, documentId, downloads} ) => {

  const { token } = useSelector( state => state.auth );
  const { accountInfo, isUserConfirmed, userType, canAccess} = useSelector((state) => state.user);
  
  let location = useLocation();
  const dispatch = useDispatch();

  let redirect_url = process.env.REACT_APP_VALID_BASE_URL;
  redirect_url = `${redirect_url}${location.pathname}`;

  const { id } = accountInfo;
  
  const distinctId = id || Mixpanel.get_distinct_id();

  const handleOnClick = (e) => {
    const menu = document.getElementsByClassName('wall');
    e.preventDefault();

    for (const element of menu) {
      element.classList.toggle('is-active');
    }

    Mixpanel.track(distinctId, 'Download Popup', {
      'Document ID': documentId,
      'Document name': combinedName,
      'Document URL': file,
      'Popup Trigger': userType === "basic" ? 'Prueba gratis vencida' : 'Sin cuenta'
    });

    window.dataLayer.push({
      event: 'popup_descarga',
      eventModel: {
        page_title: document.title,
        page_url: window.location.href,
        file_id: documentId,
        file_url: file,
        popup_trigger: userType === "basic" ? 'Prueba gratis vencida' : 'Sin cuenta'
      },
    });

  };

  const handleOnClickUnconfirmed = (e) => {
    const menu = document.getElementsByClassName('wall-unconfirmed');
    e.preventDefault();

    for (const element of menu) {
      element.classList.toggle('is-active');
    }

    Mixpanel.track(distinctId, 'Download Popup', {
      'Document ID': documentId,
      'Document name': combinedName,
      'Document URL': file,
      'Popup Trigger': 'Sin confirmación de cuenta'
    });

    window.dataLayer.push({
      event: 'popup_descarga',
      eventModel: {
        page_title: document.title,
        page_url: window.location.href,
        file_id: documentId,
        file_url: file,
        popup_trigger: 'Sin confirmación de cuenta'
      },
    })

  };

  const handledDownload = (e) => {

    //add '1' to download counter so Download buttons re-render and don't allow users to continue downloading if they are not confirmed
    if ( !isUserConfirmed ){
      dispatch( addDownloadCounterFeed ( downloads ));
      dispatch( addDownloadCounter ( downloads ));
    }
  }
  
  return (
    
    <>
      { userType === "pro" &&
      
          ( isUserConfirmed || downloads <= 0 ?
              (
                <a href={ `${file}?access_token=${token}&document_name=${combinedName}&document_id=${documentId}` } onClick={handledDownload} className="button is-normal is-white button-download" target="_blank">
                  <img className="download-icon" src="/assets/icons/Pdf-download.svg"/><span className='download-title'>Descargar</span>
                </a>
              )
            :
              (
                <a className="button is-normal is-white button-download" onClick={ handleOnClickUnconfirmed }>
                  <img className="download-icon" src="/assets/icons/Pdf-download.svg"/><span className='download-title'>Descargar</span>
                </a>
              )
           
          )
      }
      { userType === "basic" &&
        (
          canAccess ?
            (
              isUserConfirmed || downloads <= 0 ?
                (
                  <a href={ `${file}?access_token=${token}&document_name=${combinedName}&document_id=${documentId}` } onClick={handledDownload} className="button is-normal is-white button-download" target="_blank">
                    <img className="download-icon" src="/assets/icons/Pdf-download.svg"/><span className='download-title'>Descargar</span>
                  </a>
                )
              :
                (
                  <a className="button is-normal is-white button-download" onClick={ handleOnClickUnconfirmed }>
                    <img className="download-icon" src="/assets/icons/Pdf-download.svg"/><span className='download-title'>Descargar</span>
                  </a>
                )
            )
          :
            (
              <a className="button is-normal is-white button-download" onClick={ handleOnClick }>
                <img className="download-icon" src="/assets/icons/Pdf-download.svg"/><span className='download-title'>Descargar</span>
              </a>
            )
            
        )
      }
      { userType == "not logged" &&
          (
            <a className="button is-normal is-white" onClick={handleOnClick}>
                <img className="download-icon" src="/assets/icons/Pdf-download.svg" /><span className='download-title'>Descargar</span>
            </a>
          )
      }

    </>
  )
}
