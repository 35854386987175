import mixpanel from 'mixpanel-browser';
mixpanel.init(`${process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN}`, {
  persistence: "localStorage",
});

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (distinctId, name, props) => {
    if (env_check) mixpanel.track(name, Object.assign({ distinct_id: distinctId }, props));
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  get_distinct_id: () => {
    return mixpanel.get_distinct_id();
  }
};

export let Mixpanel = actions;