import React, { useEffect, useState } from 'react'

export const FrequencyList = ({ frequencyOptions, mailFrequency, disabled}) => {

    const [frequencyState, setFrequencyState] = useState({
        frequencyList: [],
        selectedOption: 7,
    });


    useEffect(() => {
        setFrequencyState({
            frequencyList: frequencyOptions,
            selectedOption: mailFrequency
        });
    }, [frequencyOptions, mailFrequency])

    const handleSelection = ( e, elementId ) => {
        // document.getElementById(`${elementId}`).classList.toggle('tag-selected');

        switch (elementId) {
            case "Inmediato":
                document.getElementById(`${elementId}`).classList.toggle('frequency-selected');
                document.getElementById(`Semanal`).classList.remove('frequency-selected');
                document.getElementById(`Quincenal`).classList.remove('frequency-selected');
                document.getElementById(`Mensual`).classList.remove('frequency-selected');
                break;
            case "Semanal":
                document.getElementById(`${elementId}`).classList.toggle('frequency-selected');
                document.getElementById(`Inmediato`).classList.remove('frequency-selected');
                document.getElementById(`Quincenal`).classList.remove('frequency-selected');
                document.getElementById(`Mensual`).classList.remove('frequency-selected');
                break;
             case "Quincenal":
                document.getElementById(`${elementId}`).classList.toggle('frequency-selected');
                document.getElementById(`Inmediato`).classList.remove('frequency-selected');
                document.getElementById(`Semanal`).classList.remove('frequency-selected');
                document.getElementById(`Mensual`).classList.remove('frequency-selected');
                break;
            case "Mensual":
                document.getElementById(`${elementId}`).classList.toggle('frequency-selected');
                document.getElementById(`Inmediato`).classList.remove('frequency-selected');
                document.getElementById(`Semanal`).classList.remove('frequency-selected');
                document.getElementById(`Quincenal`).classList.remove('frequency-selected');
                break;
            default:
                break;
        }
    }

    const { frequencyList, selectedOption } = frequencyState;

    return (
        <div className="frequency-list">
            {
                frequencyList?.map( (frequency, index) => {
                    if( frequency.frequencyNumber === selectedOption){
                        return(
                            <span
                            id={ frequency.frequencyName }  
                            key = { index } 
                            className = {`is-preference-tag frequency-selected is-inline-block ${disabled}`}
                            title = { frequency.frequencyNumber }
                            onClick={ (e) => handleSelection(e, frequency.frequencyName) }
                            >
                                { frequency.frequencyName }
                            </span>
                        );
                    }else{
                        return(
                            <span
                            id={ frequency.frequencyName }  
                            key = { index } 
                            className = {`is-preference-tag is-inline-block ${disabled}`}
                            title = { frequency.frequencyNumber }
                            onClick={ (e) => handleSelection(e, frequency.frequencyName) }
                            >
                                { frequency.frequencyName }
                            </span>
                        );
                    }
                    
                    
                })
            }  
        </div>
    )
}
