import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { PreferencesHeader } from '../user_preferences/PreferencesHeader';
import { ProgressBar } from "../ui_elements/ProgressBar";
import { PreferencesTagsList } from '../user_preferences/PreferencesTagsList';
import { getTopics } from '../../state/actions/ui';
import { getUserPreferences, saveUserPreferences, setPreferencesSaveState, saveNotificationsActivation} from '../../state/actions/user';
import { FrequencyList } from '../user_preferences/FrequencyList';
import { Footer } from '../ui_elements/Footer';
import { Link } from 'react-router-dom';
import 'animate.css';
import { validateSelectedTags } from '../../helpers/validateSelectedTags';
import { PreferencesBanner } from '../user_preferences/PreferencesBanner';
import { PageVisitTracker } from '../../helpers/PageVisitTracker';
import { Mixpanel } from '../../helpers/mixpanel';
import { ConfirmEmailWall } from '../wall/ConfirmEmailWall';

export const PreferencesPage = () => {
    const dispatch = useDispatch();

    const { preferenceTopics, topicsPending} = useSelector( state => state.ui );
    const { token } = useSelector( state => state.auth );
    const { userPreferences, preferencesSaved, selectedEmailrequency, canAccess, accountInfo } = useSelector( state => state.user);
    const [topicsList, setTopicsList] = useState({
        pending: false,
        topics: [],
        institutions: []
    })

    const [saveButtonState, setSaveButtonState] = useState({
        buttonState: false,
        notificationSate: 0,
    });

    const [selectedTagsState, setSelectedTagsState] = useState(true)

    const [notificationsActivation, setNotificationsActivation] = useState(false);

    //get the user id to pass as a property to the page visit tracker
    const { id } = accountInfo;
    let distinctId = id || Mixpanel.get_distinct_id();
    const { isUserConfirmed, userType } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch( getTopics() );
        if( token ){
            dispatch( getUserPreferences(token))
        }
        dispatch( setPreferencesSaveState(false) );
        PageVisitTracker(distinctId);
    }, [token])

    useDidUpdate( () => {
        setSaveButtonState({
            buttonState: preferencesSaved,
            notificationSate: selectedEmailrequency,
        });
    }, [preferencesSaved, selectedEmailrequency])

    useDidUpdate( () => {
        setTopicsList( {
            pending: topicsPending,
            topics: preferenceTopics.filter(isNotInstitutionTag),
            institutions: preferenceTopics.filter(isInstitutionTag),
        });
    }, [preferenceTopics, topicsPending])

    useDidUpdate( ()=> {
        setNotificationsActivation(userPreferences.active_notifications);
    }, [userPreferences])

    const isNotInstitutionTag = (tag) =>{
       return tag.tag_type != "Institución"
    }

    const isInstitutionTag = (tag) => {
        return tag.tag_type == "Institución"
    }
    
    const handleSubmit = (e) => {

        //gets the selected tags id
        const selectedTags = document.getElementsByClassName("tag-selected");
        let selectedTagsIds = [];
        for (let i = 0; i < selectedTags.length; i++) {
            let item = selectedTags[i].title;
            selectedTagsIds.push(item);
        }

        //gets the selected frequency number
        const selectedFrequency = document.getElementsByClassName("frequency-selected")
        let frequency = 0;
        if( selectedFrequency.length > 0)
            frequency = selectedFrequency[0].title;

        const optionsSelected = validateSelectedTags();
        
        if (optionsSelected){
            dispatch( saveUserPreferences(selectedTagsIds, frequency, token))
            setSelectedTagsState(true)
        }else{
            setSelectedTagsState(false)
        }
    }

    const handleSwitch = ( e ) => {
        setNotificationsActivation(e.target.checked);
        dispatch( saveNotificationsActivation (notificationsActivation, token) );    
    }

    const { pending, topics, institutions } = topicsList;
    const { buttonState, notificationSate } = saveButtonState;

    return (
        <div className="container preference-container mt-5 ml-3 mr-3">
            { (userType != "not logged" && !isUserConfirmed) &&  <ConfirmEmailWall />}
            <PreferencesHeader />
            {
                canAccess ?
                (
                    !pending ? 
                        (
                            <section className="preferences-section animate__animated animate__fadeIn"> 
                                <h1 className="preferences-title">Notificaciones personalizadas</h1>
                                <p className="is-content-text">Elige los temas e instituciones de tu interés y recibe notificaciones de acuerdo a tu preferencia.</p>
                                <br/>
                                <div className="field">
                                    <input id="switchRoundedInfo" type="checkbox" name="switchRoundedInfo" className="switch is-rounded is-info" checked={ notificationsActivation } onChange={ handleSwitch }/>
                                    <label className="is-content-text" htmlFor="switchRoundedInfo">
                                        {notificationsActivation ? "Desactivar" : "Activar"} notificaciones
                                    </label>
                                </div>
                                <p className="is-content-text"><strong>Las notificaciones están { notificationsActivation ? "activadas" : "desactivadas"}.</strong></p>
                                <p className="preferences-subtitle">Elige Temas</p>
                                <PreferencesTagsList topics = { topics } userPreferenceTopics = {userPreferences.user_preference_tags} disabled={ notificationsActivation ? "":"disabled"} />
                                <p className="preferences-subtitle">Elige Instituciones</p>
                                <PreferencesTagsList topics = { institutions } userPreferenceTopics = {userPreferences.user_preference_tags} disabled={ notificationsActivation ? "":"disabled"}/>
                                <p className="preferences-subtitle">Frecuencia de Notificaciones</p>
                                <FrequencyList frequencyOptions= {[
                                    {
                                        frequencyNumber: 1,
                                        frequencyName: "Inmediato" 
                                    },
                                    {
                                        frequencyNumber: 7,
                                        frequencyName: "Semanal" 
                                    },
                                    {
                                        frequencyNumber: 15,
                                        frequencyName: "Quincenal" 
                                    }, 
                                    {
                                        frequencyNumber: 30,
                                        frequencyName: "Mensual" 
                                    },  
                                    ]} 
                                    mailFrequency={userPreferences.mail_frequency}
                                    disabled={ notificationsActivation ? "":"disabled"}
                                />
                                <div className="buttons">
                                    <button type="submit" className="button is-info is-large submit-preferences mt-6 mb-0" onClick={handleSubmit}>
                                        {
                                            buttonState ?
                                            (
                                                <>
                                                    <i className="fa-solid fa-check-circle mr-2"></i>
                                                    Guardado
                                                </>
                                            )
                                            :
                                            (
                                                "Guardar"
                                            )
                                        }
                                    </button>
                                    
                                </div>
                                {
                                (buttonState && selectedTagsState) &&
                                (
                                    <p className="preferences-notification"> 
                                        { notificationSate == 1 ? `Recibirás tu boletin cuando haya una nueva actualización.` : `Recibirás tu boletin cada ${notificationSate} días.`} 
                                        <Link className="go-back-button" to="/"> Regresar a la búsqueda </Link> 
                                    </p>
                                )  
                                }

                                {
                                !selectedTagsState &&
                                (
                                    <p className="preferences-notification-danger">Debes seleccionar temas de tu interés y una frecuencia</p>
                                )  
                                }
                            </section>
                        )
                    :
                        (
                            <ProgressBar /> 
                        )
                )
                :
                (
                   <PreferencesBanner />
                )
            }
            <Footer />
        </div>
    )
}
