import { types } from "../types/types";
import axios from "axios";
import { baseUrl } from "../reducers/queryReducer";

export const fetchDocumentPending = () => ({
  type: types.FETCH_DOCUMENT_PENDING,
});

export const fetchDocumentSuccess = (document) => ({
  type: types.FETCH_DOCUMENT_SUCCESS,
  payload: {
    details: document.document,
    issuer: document.issuer,
    tags: document.tags,
    relatedDocuments: document.relatedDocuments,
    downloads: document.downloads,
    canAccess: document.canAccess,
    userType: document.userType,
  },
});

export const fetchDocumentError = (error) => ({
  type: types.FETCH_DOCUMENT_ERROR,
  payload: error,
});

export const addDownloadCounter = (downloads) => ({
  type: types.ADD_DOWNLOAD_COUNTER,
  payload: {
    downloads: downloads, 
  },
});

export const getDocumentById = (id, token) => {
  let accessToken = "";
  if (token !== null) accessToken = `?access_token=${token}`;

  return (dispatch) => {
    dispatch(fetchDocumentPending());

    axios
      .get(`${baseUrl}/documents/${id}${accessToken}`)
      .then((response) => {
        const document = {
          document: response.data.document,
          issuer: response.data.issuer,
          tags: !!response.tags ? [] : response.data.tags,
          relatedDocuments:
            response.data.related_documents && response.data.related_documents.length != 0
              ? response.data.related_documents
              : [],
          downloads: response.data.downloads,
          canAccess: response.data.can_access,
          userType: response.data.user_type,
        };

        dispatch(fetchDocumentSuccess(document));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchDocumentError(error));
      });
  };
};
