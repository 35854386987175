import { Wall } from '../wall/Wall';

import { DocsList } from './DocsList';


export const FeedScreen = () => {

    return (
        <div className="feed-container">
            <DocsList />
            <Wall/>
        </div>
        
    )
}
