import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { setEndDate, setStartDate } from '../../state/actions/query';
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid'
registerLocale('es', es)

export const FilterByDate = ({ from, to, isMobile}) => {
    const dispatch = useDispatch(); useDispatch
    
    const [fromDate, setFromDate] = useState(from)
    const [lastDate, setToDate] = useState(to)


    const setDate = (date) => {
        if( date == null || date == "" )
            return null;
        const newDate = parseISO(date);
        return newDate;
    }

    useDidUpdate( () => {
        let formattedDate = "";
        if( isValid(fromDate) )
            formattedDate = format(fromDate, 'yyyy-MM-dd');
        dispatch( setStartDate( formattedDate ) );
    }, [fromDate])

    useDidUpdate( () => {
        let formattedDate = ""
        if( isValid(lastDate)  )
            formattedDate = format(lastDate, 'yyyy-MM-dd');
        dispatch( setEndDate( formattedDate ) );
    }, [lastDate])
    
    return (
        <>
        {
            
            isMobile ?
            (
                
                <div className="field is-grouped date-grouped-field">
                <div className="control">
                    <img className="advanced-search-icon mr-1 date-icon" src="/assets/icons/Calendar.svg" />
                    <div className="date-picker-wrapper">
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={ setDate(from) } 
                            onChange={(date) => setFromDate(date)} 
                            className="date-input"
                            locale="es"
                            maxDate={ new Date() }
                            showYearDropdown
                        />
                    </div>
                </div>
                <div className="control">
                    <img className="advanced-search-icon mr-1 date-icon" src="/assets/icons/Calendar.svg" />
                    <div className="date-picker-wrapper">
                        <DatePicker
                            dateFormat="dd/MM/yyyy" 
                            selected={ setDate(to) } 
                            onChange={(date) => setToDate(date)} 
                            className="date-input" 
                            locale="es"
                            maxDate={ new Date() }
                            showYearDropdown 
                        />
                    </div>
                </div>  
            </div>
            
            )
            :
            (
                <div className="field">
                    <div className="date-wrapper">  
                        <img className="advanced-search-icon date-icon" src="/assets/icons/Calendar.svg" />
                        <div className="date-picker-wrapper">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={ setDate(from) } 
                                onChange={(date) => setFromDate( date )} 
                                className="date-input" 
                                locale="es"
                                maxDate={ new Date() }
                                showYearDropdown
                            />
                        </div>
                    </div>
                <div className="date-wrapper">        
                    <img className="advanced-search-icon date-icon" src="/assets/icons/Calendar.svg" />
                    <div className="date-picker-wrapper">
                        <DatePicker
                            dateFormat="dd/MM/yyyy" 
                            selected={ setDate(to) } 
                            onChange={(date) => setToDate(date)} 
                            className="date-input" 
                            locale="es"
                            maxDate={ new Date() }
                            showYearDropdown 
                        />
                    </div>
                </div>
            </div>
            )
        }
        </>
    )
}
