import { types } from "../types/types";

const initialState = {
  pending: false,
  documentDetails: {},
  issuer: null,
  documentTags: [],
  relatedDocuments: [],
  downloads: 0,
  canAccess: false,
  userType: "",
  error: "",
};

export const documentReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case types.FETCH_DOCUMENT_PENDING:
      return {
        ...state,
        pending: true,
      };

    case types.FETCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        documentDetails: actions.payload.details,
        issuer: actions.payload.issuer,
        documentTags: actions.payload.tags,
        relatedDocuments: actions.payload.relatedDocuments,
        downloads: actions.payload.downloads,
        canAccess: actions.payload.canAccess,
        userType: actions.payload.userType,
      };

    case types.FETCH_DOCUMENT_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.payload,
      };

    case types.ADD_DOWNLOAD_COUNTER:
      return {
        ...state,
        downloads: actions.payload.downloads+1
      };

    default:
      return state;
  }
};
