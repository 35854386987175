import React, { useEffect, useState } from 'react'
import { FeedScreen } from '../feed/FeedScreen'
import { Footer } from '../ui_elements/Footer'
import { Search } from '../ui_elements/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getParamValue } from '../../helpers/getParamValue'
import { getCode, getToken } from '../../state/actions/auth';
import { AdvancedSearchBar } from '../advanced_search/AdvancedSearchBar';
import TagsList from '../ui_elements/TagsList';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { setDate } from '../../helpers/setDate';
import { PageVisitTracker } from '../../helpers/PageVisitTracker';
import { Mixpanel } from '../../helpers/mixpanel';
import { ConfirmEmailWall } from '../wall/ConfirmEmailWall';

export const HomePage = () => {

    const [documentsState, setDocumentsState] = useState({
        documentCards: [],
    })

    const [tagsList, setTagsList] = useState({
        tagsArray: []
    });
    const { documents, documentsCount } = useSelector( state => state.feed );
    const { tags } = useSelector(state => state.query)
    const { code } = useSelector(state => state.auth);
    const { accountInfo } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const { documentCards } = documentsState;


    const codeParam = getParamValue("code");
    const oAuthStateParam = getParamValue("state");

    const { id } = accountInfo;
    let distinctId = id || Mixpanel.get_distinct_id();
    const { isUserConfirmed, userType } = useSelector((state) => state.user);

    useEffect(() => {
        if(codeParam){
            dispatch( getCode( codeParam, oAuthStateParam ) );
        }
        PageVisitTracker(distinctId);
        
    }, []);

    useDidUpdate(() => {

        dispatch( getToken(
            process.env.REACT_APP_TOKEN_HOST_SERVER_URL,
            process.env.REACT_APP_CLIENT_ID,
            process.env.REACT_APP_CLIENT_SECRET,
            process.env.REACT_APP_REDIRECT_URI,
            code
        ));
    }, [code])


    useEffect(() => {
        setDocumentsState({ documentCards: documents});
    }, [documents])


    useDidUpdate(() => {
        setDate( documentCards );
    }, [documentCards])


    useDidUpdate( () => {
        setTagsList( { tagsArray: tags} );
    }, [tags, documentCards]);

    const handleAdvancedSearch = ( e ) =>{
        e.preventDefault();
        const advancedSearchModal = document.getElementById("advancedSearchModal");
        advancedSearchModal.classList.toggle('is-active');
    }
    const { tagsArray } = tagsList;

    return (
        <>
            { (userType != "not logged" && !isUserConfirmed) &&  <ConfirmEmailWall />}
            <div className="home-page mt-5 ml-3 mr-3">
                <div className="columns ">
                    
                    <section className="left-container is-desktop column is-9">
                        <a href="/">
                            <h1 className="is-title-brand has-text-centered">Valid de TodoLegal</h1>
                        </a>
                        <h2 className="has-text-centered brand-motto">El buscador de Decretos, Acuerdos y Gacetas de Honduras</h2>
                        <header className="home-header" id="homeHeader">
                           
                            <div className="columns mt-3">
                                <div className="column is-8 is-offset-2">
                                    <Search handleAdvancedSearch = { handleAdvancedSearch }/>
                                    <TagsList tagsArray = { tagsArray } advancedSearchTags={true}/>
                                    <div className="documents-count mt-2">
                                        { (documentsCount > 0) && <p className="has-text-grey"> { documentsCount.toLocaleString() } resultados </p>}
                                    </div>
                                </div>
                            </div>
                            <div className="date-container" id="dateContainer">  </div>
                        </header>
                        <FeedScreen />
                    </section>
                    
                    
                    <div className="modal" id="advancedSearchModal">
                        <div className="modal-background"></div>
                        <div className="modal-card advanced-search-container">
                            <section className="modal-card-body">
                                <AdvancedSearchBar handleAdvancedSearch = { handleAdvancedSearch } isMobile = { true } />
                            </section>
                        </div>
                    </div>
                        
                    <aside className="right-container advanced-search column mt-3 is-3 is-hidden-mobile">
                        <AdvancedSearchBar handleAdvancedSearch = { handleAdvancedSearch } isMobile = { false }/>
                    </aside>

                </div>
                <Footer />
            </div>
        </>
    )
}