import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { resetTag, setTagFilter } from '../../state/actions/query';

export const Dropdown = ({ values, tagType, defaultValue }) => {

    const dispatch = useDispatch();
    const selectRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState({
        label: defaultValue,
        value: defaultValue
    });

    const [isClearable, setIsClearable] = useState(false);

    let selectValues = [];

    selectValues = values.map( ( element, index) => {
        return { value: element.name, label: element.name};
    })

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            marginTop: "10px"
        }),

        control: (provided, state) => ({
          ...provided,
          backgroundColor: "#F5F5F5",
          border: "none"
        }),

        dropdownIndicator: (provided, state) =>({
            ...provided,
            backgroundColor: "#1A7FFA"
        }),

        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none"
        }),

        clearIndicator: (provided, state) => ({
            ...provided,
            backgroundColor: "#F5F5F5",
        })
    }

    useDidUpdate( () => {
        if(selectedOption){
            setIsClearable(true)
            dispatch( setTagFilter( selectedOption.value, tagType) );
        }else{
            dispatch( resetTag( tagType) );
            setIsClearable(false)
        } 
    }, [selectedOption])


    useDidUpdate( () => {
        if( defaultValue == null)
            selectRef.current.clearValue();
    }, [defaultValue])

    
    return (
        <Select
            ref={selectRef}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={selectValues}
            styles={customStyles}
            isClearable={isClearable}
        />
    )
}
