import React from 'react'
import { Link } from 'react-router-dom'

export const PreferencesHeader = () => {
    return (
        <> 
            <div className="title-container mt-2 mb-4">
                <a href="/">
                    <h1 className="is-title-brand has-text-centered">Valid de TodoLegal</h1>
                </a>
                <h2 className="has-text-centered brand-motto">El buscador de Decretos, Acuerdos y Gacetas de Honduras</h2>
            </div>
            <Link className="go-back-button" to="/">
                <i className="fa-solid fa-arrow-left mr-3"></i>  Regresar a la búsqueda
            </Link>
            <hr className="mt-2"/>
        </>
    )
}
