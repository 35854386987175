import React, { useState } from 'react';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { useForm } from '../../hooks/useForm';
import 'animate.css';
import TagManager from 'react-gtm-module';

export const MailingCard = ({ status, message, onValidated}) => {
    
    const [inputValue, handleInputChange, reset ] = useForm({
        email: ""
    });

    const [mailStatus, setMailStatus] = useState("");

    const { email } = inputValue;

    const handleSubmit = ( e ) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 && 
        onValidated({
            EMAIL: email,
        });
        reset();

        window.dataLayer.push({
            event: 'subscripcion_boletin',
        });
    }

    const handleHideComponent = ( e ) => {
        document.getElementById("mailingCard").classList.add("mailing-card-hidden");
    }

    useDidUpdate(() => {
        setMailStatus(status);
        if (status === "success"){
            setTimeout( () => {
                const mailingCard = document.getElementById("mailingCard")
                if( mailingCard )
                    mailingCard.classList.add("mailing-card-hidden");
            }, 6000)
        }
    }, [status])

    return (
        <article className="card mailing-card" id="mailingCard">
            <div className="card-content">
                <div className="columns is-mobile">
                    <div className="column">
                        <button className="delete is-medium" onClick={handleHideComponent}></button>
                       
                        { mailStatus !== "success" && (
                             <h1 className="is-title-document mb-1">Suscribete a nuestro boletín semanal</h1>
                        )}
                        { mailStatus === "sending" && (
                            <div className="mailchimp-alert mailchimp-sending-alert">
                                Enviando...
                            </div>
                        )}
                        { mailStatus === "error" && (
                            <div className="mailchimp-alert mailchimp-error-alert">
                                Ha ocurrido un error enviando tu correo, recarga la página e intenta de nuevo.
                            </div>
                        )}
                        { mailStatus === "success" && (
                            <h1 className="is-title-document mb-2 animate__animated animate__fadeIn">Gracias por suscribirte</h1>
                        )}
                        <div className="content">
                            { mailStatus !== "success" && (
                                <>
                                    <p className="mb-1">Déjanos tu correo y recibirás un resumen semanal con los cambios de las normativas más importantes del país.</p>
                                    <form className="field is-grouped" onSubmit={ handleSubmit }>
                                        <div className="field has-addons">
                                            <div className="control">
                                                <input 
                                                    className="input" 
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="control">
                                                <button 
                                                    className="button is-info"
                                                    type="submit"
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            )}
                            { mailStatus === "success" && (
                                <p className="mb-4 success-text animate__animated animate__fadeIn">Recibirás noticias con las nuevas actualizaciones de manera semanal.</p>
                            )}
                           
                        </div>

                    </div>
                </div>
            </div>
        </article>
    )
}
