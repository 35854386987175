import React from 'react';
import { useLocation } from 'react-router-dom';

export const LoginButton = ({ buttonText = "Inicia sesión", buttonClass = "button is-link"}) => {
    
    let location = useLocation()
    let redirect_state = location.pathname;

    const oAuthLogin = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&state=${redirect_state}`;
    
    return (
        <div>
            <a className={buttonClass} href={ oAuthLogin }>
                <strong>{buttonText}</strong>
            </a>
        </div>
    )
}
