import { types } from '../types/types';
import axios from 'axios';

export const fetchTagsListPending = ( ) => ({
    type: types.GET_TAGS_lIST_PENDING
})

export const fetchTagsListSuccess = ( tags, tagType ) => ({
    type: types.GET_TAGS_lIST_SUCCESS,
    payload: {
        tags,
        tagType
    }
})

export const fetchTagsListError = ( error ) => ({
    type: types.GET_TAGS_lIST_ERROR,
    payload: error
})

export const getTopicsSuccess = ( topics ) => ({
    type: types.GET_TOPICS_SUCCESS,
    payload: {
        topics: topics.preferenceTopics
    }
})

export const getTopicsPending = () => ({
    type: types.GET_TOPICS_PENDING,
})

export const getTopicsError = ( error ) => ({
    type: types.GET_TOPICS_PENDING,
    payload: error
})


export const showScrollProgressBar = () => ({
    type: types.SET_LOADING
})

export const removeLoading = () => ({
    type: types.REMOVE_LOADING
})

const baseUrl = `${process.env.REACT_APP_TL_BASE_URL}`;

export const getTagsList = () => {
    
    return( dispatch ) => {

        const institutionTags = axios.get(`${baseUrl}/api/v1/tags?type=Institución`);
        const actTypeTags = axios.get(`${baseUrl}/api/v1/tags?type=Tipo%20de%20Acto`);
        const topicTags = axios.get(`${baseUrl}/api/v1/tags?type=Tema`);

        dispatch( fetchTagsListPending() );

        const tagsPromises = [institutionTags, actTypeTags, topicTags];
        
        Promise.allSettled( tagsPromises)
        .then( responses => {
            dispatch( fetchTagsListSuccess (responses[0].value.data.tags, 1))
            dispatch( fetchTagsListSuccess (responses[1].value.data.tags, 2))
            dispatch( fetchTagsListSuccess (responses[2].value.data.tags, 3))
        })
        .catch( ( error ) => {

            // console.log( error );
            dispatch( fetchTagsListError( error ) );

        }); 
    }
}

export const getTopics = () => {
    return ( dispatch ) => {
        dispatch( getTopicsPending() );
        axios.get(`${baseUrl}/api/v1/tags/preference_tags`)
        .then(response => {
            const preferenceTopics = {
                preferenceTopics: response.data.preference_tags
            }
            dispatch( getTopicsSuccess( preferenceTopics ) );
        })
        .catch( error => {
            console.log("Error getting topcis: ", error);
            dispatch( getTopicsError(error) );
        })
    }
}

export const showSessionNotification = () => ({
    type: types.SHOW_SESSION_NOTIFICATION
})

export const showEmailConfirmationNotification = () => ({
    type: types.SHOW_EMAIL_CONFIRMATION_NOTIFICATION
})