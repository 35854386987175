
function get_autos_title ( name, issue_id, alternative_issue_id){
    name = name.replace(alternative_issue_id, "");
    name = name.replace(`Auto Acordado ${issue_id}`, "");
    return name;
}


export const getDocumentTitle = (name="", issue_id="", document_type="", alternative_issue_id="") => {

    if( name == ""){
        name = null;
    }

    if( issue_id == "" ){
        issue_id = null;
    }

    if ( document_type == "Auto Acordado"){
        return get_autos_title(name, issue_id, alternative_issue_id )
    }

    if( name !== null && issue_id !== null ) {
        return name + ', ' + issue_id;
    }else if ( name !== null && issue_id === null ) {
        return name;
    }else if ( name === null && issue_id !== null ) {
        return issue_id;
    }else {
        return ""
    }

}