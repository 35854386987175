export const getDateInWords = ( date ) => {
    const monthsArray = [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ];
    
    let dateArray = ""
    dateArray = date?.split('-');
    let dateInWords = ``;
    
    if( dateArray ) {
        let year = dateArray[0];
        let month = dateArray[1];
        month = monthsArray[month - 1];
        let day = dateArray[2];

        dateInWords = `${day?.trim()}.${month?.trim()}.${year?.trim()}`;
    }

    return dateInWords
}
