import queryString from "query-string";
import { Mixpanel } from "./mixpanel";

export const PageVisitTracker = ( distinctId ) => {

  const params = queryString.parse(location.search);
  const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } = params;       
  Mixpanel.track(distinctId, "Site Visit", { utm_source, utm_medium, utm_campaign, utm_content, utm_term });

}
