import React from 'react';

const EditButton = ({ link }) => {

  const handleClick = (e) => {
    e.stopPropagation(); // This will stop the event from bubbling up to the parent element
  };
  
  return (
    <a href={link} rel="noopener noreferrer" target='_blank' onClick={handleClick} >
      <span className="icon edit-icon">
        <i className="fa-solid fa-pen-to-square"></i>
      </span>
    </a>
  );
};

export default EditButton;
