import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const Banner = () => {

  //redirect uri for doorkeeper authentication
  let location = useLocation()
  let redirect_state = location.pathname;

  //redirect url for already logged in users updating their plan
  let redirect_url = process.env.REACT_APP_VALID_BASE_URL;
  redirect_url = `${redirect_url}${location.pathname}`; 

  const oAuthSignUp = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&go_to_signup=true&state=${redirect_state}`;
  const oAuthLogin = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&state=${redirect_state}`;
  const goToPricing = `${process.env.REACT_APP_TL_BASE_URL}/pricing?return_to=${redirect_url}`

  const { downloads, canAccess } = useSelector((state) => state.document);
  const { userType, isUserConfirmed, remainingTrialDays } = useSelector((state) => state.user);

  const isProUser = userType == "pro" ? true : false;
  const basicUser = userType == "basic" ? true : false;
  const noUser = userType == "not logged" ? true : false;

  return (
    <>
      {
        isProUser ?
          (
            !isUserConfirmed  && (
              <section className="banner-download hero is-small">
                <div className="hero-body">
                  <div className="container has-text-centered">
                    <p className="subtitle">
                      Gracias por suscribirte a Valid de TodoLegal. El último paso es confirmar tu cuenta por correo electrónico. Si no lo tienes,
                      <a href={`${process.env.REACT_APP_TL_HOME}/send_confirmation_email?redirect_to=${process.env.REACT_APP_VALID_BASE_URL}`}> <strong className="is-blue-link"> haz click para reenviar el correo.</strong></a>
                    </p>
                  </div>
                </div>
              </section>
            )
          )
        :
          (
            <section className="banner-download hero is-small">
              <div className="hero-body">
                <div className="container has-text-centered">
                  <p className="subtitle">
                      {noUser && (
                        <a href={oAuthSignUp} className="link-banner"> <strong>Regístrate</strong></a>
                      )}
                      {noUser && <span>&nbsp;o&nbsp;</span>}
                      {noUser && (
                        <>
                          <a className="link-banner" href={oAuthLogin}><strong>inicia sesión </strong></a>
                          para que puedas descargar los documentos que deseas.
                        </>
                      )}
                      
                      {basicUser && (

                        canAccess ?
                          (
                            isUserConfirmed ?
                              (
                                <>
                                  Te quedan {`${remainingTrialDays}`} días disponibles de tu prueba gratuita. Adquiere uno de nuestros
                                  <a href={goToPricing} ><strong className="is-blue-link"> planes </strong></a>
                                  y usa la plataforma de manera ilimitada.
                                </>
                              )
                            :
                              (
                                <>
                                  Para utilizar tu prueba gratuita, solo falta confirmar tu correo electrónico.
                                  <a href={`${process.env.REACT_APP_TL_HOME}/send_confirmation_email?redirect_to=${redirect_url}`}> <strong className="is-blue-link"> Haz click para reenviar el correo.</strong></a>
                                </>
                              )
                          )
                        :
                          (
                            <>
                              Tu prueba gratuita ha expirado. Para seguir utilizando nuestro servicio, puedes adquirir uno de
                              <a href={goToPricing} ><strong className="is-blue-link"> nuestros planes </strong></a>
                              o contactar un agente de ventas dando <a href="https://wa.me/50494714842" target="_blank"><strong className="is-blue-link"> click aquí </strong></a>.
                            </>
                          )   
                         
                      )}
                    
                  </p>
                </div>
              </div>
            </section>
          )
      }
    </>
  );
};
