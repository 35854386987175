import { types } from '../types/types';

// localStorage.setItem('access_token', '');

const initialState = {
    code: '',
    token: localStorage.getItem('access_token'),
    oAuthState: '',
    error: ''
}

export const authReducer = ( state = initialState, actions) => {
    switch ( actions.type) {
        case types.GET_CODE_SUCCESS:
            return{
                ...state,
                code: actions.payload.code,
                oAuthState: actions.payload.oAuthState
            }
        case types.GET_TOKEN_SUCCESS:
            return{
                ...state,
                token: actions.payload
            }
        case types.GET_TOKEN_ERROR:
            return{
                ...state,
                error: actions.payload
            }
        case types.REMOVE_TOKEN:
            return{
                ...state,
                token: actions.payload
            }
        default:
            return state;
    }
}