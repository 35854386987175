import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDidUpdate } from '../../hooks/useDidUpdate';
import { useForm } from '../../hooks/useForm';
import { resetCounterState, resetQueryStateValue, withQuery } from '../../state/actions/query';
import { setLoading } from '../../state/actions/ui';

export const Search = ({ handleAdvancedSearch, isInDocumentPage=false }) => {


    const dispatch = useDispatch();

    const { token } = useSelector( state => state.auth );
    const { queryValue } = useSelector(state => state.query);
    const { userType, canAccess} = useSelector((state) => state.user);

    const [inputValue, handleInputChange, reset ] = useForm({
        query: queryValue
    });

    const { query } = inputValue;

    let navigate = useNavigate();

    const handleSubmit = ( e ) => {
        e.preventDefault();
        navigate("/");

        if( query.length > 0 ){
            // dispatch( setLoading() );
            dispatch( resetCounterState() );
            dispatch( withQuery( query, token ));
            const element = document.getElementById('validNavbar');
            element.scrollIntoView({behavior: "smooth"});
        }  
    }

    const handleClick = ( e ) => {
        if( canAccess && query.length > 0 ){
            navigate("/");
            dispatch( resetCounterState() );
            dispatch( withQuery( query, token ));
        }else{
            e.preventDefault();
            const menu = document.getElementsByClassName('wall');
            for (const element of menu) {
                element.classList.toggle('is-active');
            }

            // Unfocus the search button
            e.target.blur();
        } 
    }
    
    // This function handles the enter key event on the search bar.
    // If the user does not have access, it toggles the "is-active" class on the "wall" component.
    const handleEnterKey = ( e ) => {
        if (e.key === 'Enter' && !canAccess) {
            const menu = document.getElementsByClassName('wall');
            e.preventDefault();

            for (const element of menu) {
                element.classList.toggle('is-active');
            }

            // Unfocus the search bar
            e.target.blur();
        }
    }

    useDidUpdate(() =>{
        if( query == "" ){
            dispatch( resetQueryStateValue() );
        }
    }, [query])

    return (
        
        <form className="field is-grouped search-bar-container" onSubmit={ handleSubmit }>
            <div className="control is-expanded search-bar">
                <input 
                    className="input"
                    id="searchInput"
                    name="query" 
                    type="text"
                    value={ query }
                    onChange={ handleInputChange }
                    onKeyDown={ handleEnterKey }
                    placeholder="Buscar por título, descripción, institución"
                />
            </div>
            <div className="control">
                <button 
                    className="button is-primary is-hidden-mobile search-button"
                    type="button"
                    id="searchBtnDesktop"
                    onClick={ handleClick }
                >
                    Buscar
                </button>

                <button 
                    className="button is-primary is-hidden-tablet search-button"
                    type="button"
                    id="searchBtnMobile"
                    onClick={ handleClick }
                >
                    <i className="fa-solid fa-search"></i>
                </button>
            </div>
            <div className="control">
                 <button 
                    className="button is-white advance-search-mobile advanced-search-button pl-0 pr-0"
                    type="button"
                    onClick={ handleAdvancedSearch }
                >
                    <img className="filter-icon" src="/assets/icons/Filters.svg" />
                </button>
            </div>
        </form>
    )
}