import { types } from '../types/types';

const initialState = {
    showScollLoading:  false,
    message: null,
    institutionTags: [],
    actTypeTags: [],
    topicTags: [],
    tagsPending: false,
    tagsError: null,
    showSessionWarning: false,
    showEmailSentNotification : false,
    preferenceTopics: [],
    topicsPending: false,
    topicsError: null,
}


export const uiReducer = ( state = initialState, actions ) => {

    switch ( actions.type ) {
        case types.SET_LOADING:
            return {
                ...state,
                showScollLoading: true
            }

        case types.REMOVE_LOADING:
            return {
                ...state,
                showScollLoading: false
            }

        case types.GET_TAGS_lIST_SUCCESS:

            if(actions.payload.tagType == 1){
                return {
                    ...state,
                    tagsPending: false,
                    institutionTags: actions.payload.tags
                }
            }else if( actions.payload.tagType == 2){
                return {
                    ...state,
                    tagsPending: false,
                    actTypeTags: actions.payload.tags
                }
            }else{
                return {
                    ...state,
                    tagsPending: false,
                    topicTags: actions.payload.tags
                }   
            }

        case types.GET_TAGS_lIST_PENDING:
            return{
                ...state,
                pending: true
            }
        
        case types.GET_TAGS_lIST_ERROR:
            return{
                ...state,
                pending: false,
                tagsError: actions.payload
            }

        case types.SHOW_SESSION_NOTIFICATION:
            return {
                ...state,
                showSessionWarning: true
            }

        case types.SHOW_EMAIL_CONFIRMATION_NOTIFICATION:
            return {
                ...state,
                showEmailSentNotification: true
            }

        case types.GET_TOPICS_SUCCESS:
            return {
                ...state,
                topicsPending: false,
                preferenceTopics: actions.payload.topics
            };
        
        case types.GET_TOPICS_PENDING:
            return {
                ...state,
                topicsPending: true
            };

        case types.GET_TOPICS_ERROR:
            return {
                ...state,
                pending: false,
                topicsError: actions.payload
            };
            
        default:
            return state;
    }
}