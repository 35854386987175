
export const types = {

    //Search types
    FETCH_DOCUMENTS_PENDING: '[Search] Getting documents',
    FETCH_DOCUMENTS_SUCCESS: '[Search] Documents fetched',
    FETCH_DOCUMENTS_ERROR: '[Search] Error fetching documents',
    ADD_DOWNLOAD_COUNTER_FEED: '[Feed] Add one to download counter',



    //UI types
    SET_LOADING: '[UI] Set Loading progress bar',
    REMOVE_LOADING: '[UI] Remove loading progess bar',
    DATE_CHECKBOX_STATE: "[UI] The date checkbox's state has changed",
    GET_TAGS_lIST_SUCCESS: "[UI] Tags fetched successfully",
    GET_TAGS_lIST_PENDING: "[UI] Fetching tags list",
    GET_TAGS_lIST_ERROR: "[UI] Error: Tags list not fetched",
    SHOW_SESSION_NOTIFICATION: "[UI] Error: User credentials already being used in other session",
    SHOW_EMAIL_CONFIRMATION_NOTIFICATION: "[UI] Show email confirmation notification",
    GET_TOPICS_SUCCESS: '[UI] Topics fetched successfully',
    GET_TOPICS_PENDING: '[UI] Fetching topics',
    GET_TOPICS_ERROR: '[UI] Error fetching topics',

    //Document types
    FETCH_DOCUMENT_PENDING: '[Doc Screen] Getting document',
    FETCH_DOCUMENT_SUCCESS: '[Doc Screen] Document fetched',
    FETCH_DOCUMENT_ERROR: '[Doc Screen] Error fetching document',
    ADD_DOWNLOAD_COUNTER: '[Doc Screen] Add one to download counter',

    //query types
    ALL_DOCUMENTS: '[Query] Will fetch all documents',
    WITH_QUERY: '[Query] Will fetch documents with query',
    WITH_DATE_FILTER: '[Query] Will fetch documents between dates',
    WITH_CURRENT_URL_AND_LIMIT: '[Query] Will fetch documents with query and a limit',
    WITH_QUERY_OFFSET_AND_LIMIT: '[Query] Will fetch documents with query, offset and limit',
    RESET_CURRENT_URL: '[Query] Reset current url to base url',
    INCREMENT_COUNTER_STATE: '[Query] Increment counter state',
    RESET_COUNTER_STATE:'[Query] Reset counter state',
    RESET_QUERY_VALUE: '[Query] Reset the query state to its initial value',
    WITH_TAGS: '[Query] Will fetch documents with tags',
    RESET_TAG_FILTERS: '[QUERY] Reset tag filter',
    SET_FILTER_START_DATE: '[QUERY] Set filter start date',
    SET_FILTER_END_DATE: '[QUERY] Set filter end date',
    RESET_DATE_FILTER: '[QUERY] Reset dates filter',
    SET_INSTITUTION_TAG: '[QUERY] Set institution tag',
    SET_ACT_TYPE_TAG: '[QUERY] Set act type tag',
    SET_TOPIC_TAG: '[QUERY] Set topic tag',
    RESET_INSTITUTION_TAG: '[QUERY] Reset institution tag',
    RESET_ACT_TYPE_TAG: '[QUERY] Reset act type tag',
    RESET_TOPIC_TAG: '[QUERY] Reset topic tag',

    //Auth types:
    GET_CODE_SUCCESS: '[Auth] Exchange code obtained successfully',
    GET_TOKEN_SUCCESS: '[Auth] Authentication token obtained succesfully',
    GET_TOKEN_ERROR: '[Auth] Error while getting authentication token',
    REMOVE_TOKEN: '[Auth] Removed token from localStorage',

    //User types:
    GET_USER_SUCCESS: '[User] User fetched successfully',
    GET_USER_ERROR: '[User] Error getting user',
    GET_USER_PENDING: '[User] Fetching user data',
    GET_USER_PREFERENCES_SUCCESS: '[User] User preferences fetched successfully',
    GET_USER_PREFERENCES_ERROR: '[User] Error getting user preferences',
    GET_USER_PREFERENCES_PENDING: '[User] Fetching user preferences',
    SAVE_USER_PREFERENCES: '[USER] Saving user preferences',
    SET_USER_PREFERENCES: '[USER] Set user preference values',
    SET_NOTIFICATIONS_ACTIVATION: '[USER] Toggles notifications activation',
    SAVE_NOTIFICATIONS_ACTIVATION: '[USER] Saving notifications activation',
    LOGOUT: '[USER] Logged out'
}