import React from 'react'
import { Wall } from '../wall/Wall';

export const PreferencesBanner = () => {

  const goToPricing = `${process.env.REACT_APP_TL_BASE_URL}/pricing?return_to=${process.env.REACT_APP_VALID_BASE_URL}/users/preferences`;

  return (
    <>
      <Wall/>
      <section className="banner-download hero is-small">
          <div className="hero-body">
          <div className="container has-text-centered">
              <p className="title mb-6">
                  Activa tus notificaciones
              </p>
              <p className="subtitle">
                  Para continuar recibiendo alertas legales de acuerdo a los temas de tu interés, debes adquirir unas de nuestras suscripciones. 
              </p>
              <p className="control">
                  <a 
                      href={goToPricing} 
                      className="button mr-2 is-primary"
                  >
                      Ver planes
                  </a>
              </p>
          </div>
          </div>
      </section> 
    </>
  )
}
