import React from 'react';
import { DocumentCard } from '../ui_elements/DocumentCard';

export const RelatedDocuments = ({ relatedDocuments, id }) => {
    
    //Add file field to related documents API
    return (
        <div className="related-documents">
            <p className="related-documents-title"> Documentos relacionados </p>
            <div className="documents-list">
                {
                    relatedDocuments?.map( doc => {
                        if ( doc.id != id) {
                            return(
                                <DocumentCard 
                                    key={ doc.id }
                                    {...doc}
                                />
                            );
                        }
                    })
                }
            </div>
        </div>
    )
}
