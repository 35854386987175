import React from 'react'
import { useLocation } from 'react-router-dom'
import { LoginButton } from '../auth/LoginButton'

export const LoggedOutNavbar = () => {

    let location = useLocation()
    const handleToggle = (e) => {
        const menu = document.querySelector('.mobile--menu');
        e.preventDefault();
        menu.classList.toggle('is-active');
    }

    let redirect_state = location.pathname;

    const oAuthSignUp = `${process.env.REACT_APP_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=login&go_to_signup=true&state=${redirect_state}`;

    return (
        <nav className="navbar main--navbar" role="navigation" aria-label="main navigation" id="validNavbar">
            <div className="navbar-brand">
                <div className="navbar-item">
                  <a className="" href={`${process.env.REACT_APP_TL_BASE_URL}`}>
                    <img className="logo-icon" src="/assets/tl_icon_white.svg" />
                  </a>
                  <img  src="/assets/white_line.png" alt="divider" className="vertical-line" />
                  <a className="navbar-item" href="/">
                    <img className="logo-letter" src="/assets/logo-valid-letras.png" />
                  </a>
                </div>
                
                <div className="navbar-item is-hidden-desktop">
                    <a href={oAuthSignUp}>
                        <span className="icon"><i className="fa-solid fa-bolt"></i></span>
                        Suscríbete
                    </a>
                </div>
                <a role="button" className="navbar-burger" onBlur={ handleToggle } onClick={ handleToggle } aria-label="menu" aria-expanded="false">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
    
            <div id="mobileMenu" className="navbar-menu mobile--menu">
                <div className="navbar-end">
                    <div className="navbar-item is-hidden-touch"> 
                        <a href={oAuthSignUp}>
                            <span className="icon"><i className="fa-solid fa-bolt"></i></span>
                            Suscríbete
                        </a>
                    </div>
                    <div className="navbar-item">
                        <LoginButton buttonClass="login--link" />
                    </div>
                    <div className="navbar-item is-hidden-mobile">
                        <a href={oAuthSignUp} className="register--link">
                            <strong>Regístrate</strong>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    )
}
