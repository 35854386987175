import { combineReducers } from 'redux';
import { feedReducer } from './feedReducer';
import { uiReducer } from './uiReducer';
import { documentReducer } from './documentReducer'
import { queryReducer } from './queryReducer';
import { authReducer } from './authReducer';
import { userReducer } from './userReducer';

export const rootReducer = combineReducers({
    feed: feedReducer,
    ui: uiReducer,
    document: documentReducer,
    query: queryReducer,
    auth: authReducer,
    user: userReducer
})

