import React from 'react'

export default function TagsList({ tagsArray, advancedSearchTags=false }) {
    return (
        <>
            {
                tagsArray?.map( (tag, index) => {
                    //if tags are from the advanced search they do not have properties, just a string value
                    if( advancedSearchTags ){
                        return(
                            <span  
                                key = { index} 
                                className = "is-tag is-inline-block"
                                value = { tag }
                            >
                                { tag }
                            </span>
                        );
                    }else{
                        
                        if( tag.name != ""){
                            return(
                                <span  
                                    key = { index} 
                                    className = "is-tag is-inline-block"
                                    value = { tag.name }
                                >
                                    { tag.name }
                                </span>
                            );
                        }

                    }
                })
            }
        </>
    )
}
